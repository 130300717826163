<template>
  <div class="q-mt-lg" v-if="zavodnik.length">
        <RacersTab 
            :zavodnik="zavodnik"
        />
    </div>
</template>

<script setup>

import { ref, onMounted, watchEffect } from 'vue'
import { db } from '@/firebase/config'
import { collection, onSnapshot } from 'firebase/firestore'


import RacersTab from '@/components/admin/RacersTab.vue'


    const zavodnik = ref([])


    const ziskejData = () => {
        const unsub = onSnapshot(collection(db,'zavodniciDtHolstejn', 'DT2024', 'private'), snapshot => {
            let results = []
            snapshot.docs.forEach(doc => {
                results.push({ ...doc.data(), id: doc.id })
                zavodnik.value = results
                //console.log(zavodnik.value)
            })
        })
        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub())
        })
    }

        /*
        const respublic = ref(null)
        const resprivate = ref(null)

        const ziskejData = () => {
            const unsub = onSnapshot(collection(db,'zavodniciDtHolstejn', 'DT2024', 'public'), snapshot => {
                let results = []
                snapshot.docs.forEach(doc => {
                    results.push({ ...doc.data(), id: doc.id })
                })
                respublic.value = results
                onSnapshot(collection(db,'zavodniciDtHolstejn', 'DT2024', 'private'), snapshot => {
                    let results = []
                    snapshot.docs.forEach(doc => {
                        results.push({ ...doc.data(), id: doc.id })
                    })
                    resprivate.value = results
                    sloucitObjekty()

                })
            })
            watchEffect((onInvalidate) => {
                onInvalidate(() => unsub())
            })
        }


        //SLOUČENÍ OBJEKTŮ
        const sloucitObjekty = () => {
            const novePole = []
            respublic.value.forEach(objekt1 => {
                const shodnyObjekt = resprivate.value.find(objekt2 => objekt2.id === objekt1.id)

                if (shodnyObjekt) {
                const sloucenyObjekt = { ...objekt1, ...shodnyObjekt }
                novePole.push(sloucenyObjekt)
                }
            })

            zavodnik.value = novePole

        }
        */
        onMounted(() => {
            ziskejData()
        })

</script>

<style>

</style>
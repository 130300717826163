<template>
    <NavBar />
    <div  style="max-width: 700px; margin: auto">
      <h3>Statistiky</h3>
      <p v-if="zavodnici">Celkem přihlášených závodníků: {{ zavodnici }} z toho placeno: {{ placeni }}</p>
      <StatsTab />
      <StatsTab2 />

    </div>
  
</template>

<script>
import NavBar from '@/components/admin/NavBar.vue'
import StatsTab from '@/components/admin/StatsTab.vue'
import StatsTab2 from '@/components/admin/StatsTab2.vue'



import { ref } from 'vue'



import getCount from '@/composables/getCount'

export default {
  components: { NavBar, StatsTab, StatsTab2 },

  setup() {

    //volání externí fce na zjištění počtu přihlášenáých závodníků
    const zavodnici = ref(null)    
    const fetchCount = async() => {
      try {
        const { count } = await getCount(['zavodniciDtHolstejn', 'DT2024', 'public'])
        zavodnici.value = count
      } catch (error) {
        console.error('Error:', error)
      }
    }
    fetchCount()

    const placeni = ref(null)    
    const fetchPlatby = async() => {
      try {
        const { count } = await getCount(
          ['zavodniciDtHolstejn', 'DT2024', 'public'],
          ['zaplaceno', '==', true]
          )
        placeni.value = count
      } catch (error) {
        console.error('Error:', error)
      }
    }
    fetchPlatby()




    const longariPlaceni = ref(null)    
    const fetchLongariPlaceni = async() => {
      try {
        const { count } = await getCount(
          ['zavodniciDtHolstejn', 'DT2024', 'public' ],
          ['trasa', '==', 'long'],
          ['zaplaceno', '==', true]
          )
        longariPlaceni.value = count
      } catch (error) {
        console.error('Error:', error)
      }
    }
    fetchLongariPlaceni()









    return {
      zavodnici,
      placeni,
    }
  }

}
</script>

<style>

</style>
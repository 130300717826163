<template>
    <div class="full-width row wrap justify-center items-center content-center" style="height: 100vh; align: center;">
        <div 
            class="col-12"
            style="height: 100%; width: 100%; background-color: white; opacity: 65%; z-index: 100; position: fixed; top: 0; left: 0;"
            @click="toggleDotaz">    
        </div>                                
        <div class="modal col-xs-12 col-sm-8 col-md-5 rounded self-start q-pa-xl shadow-5" 
            :class="{
                warning: theme === 'warning',
                gdpr: theme === 'gdpr', 
                chyba: theme === 'chyba',
                photo: theme === 'photo',
              
            }" 
            style="z-index:300">
            <div style="position: absolute; top: 0; right: 0; z-index: 800">
                <q-btn round outline color="dark" icon="close" padding="none" size="10px" @click="toggleDotaz" />
            </div>
            <slot>
                <p>Opravdu se chcete přihlásit na trek, aniž by bylo známé datum a přesné propozice?</p>
                <div class="q-pa-md q-gutter-sm">
                    <q-btn type="submit" @submit="handleSubmit" style="background: #f5f3f1; color: #726d65" size="md">Odeslat přihlášku</q-btn>
                    <q-btn @click="toggleDotaz" style="background: #f5f3f1; color: black" size="xl">Nechci</q-btn>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['theme'],
    emits: ['toggleDotaz', 'handleSubmit'],

    setup(props ,emits) {
        
        const toggleDotaz = (() =>{
            emits.emit('toggleDotaz')
        })

        const handleSubmit = (() =>{
            emits.emit('handleSubmit')
        })



        return {toggleDotaz, handleSubmit}
    }
}

</script>

<style>

</style>
<template>
    <div class="row ">
        <div class="col-12 col-md-10 col-lg-7 q-gutter-sm">
            <h4>Vložení nové aktuality</h4>
        </div>
        <form @submit.prevent="handleSubmit" 
            class="col-12 col-md-10 col-lg-7 q-gutter-sm"
            shouldFocus: true
            >
            <q-input 
                outlined
                type="text"
                label="Nadpis"
                v-model="nadpis" 
            />
            <q-input 
                outlined
                type="date"
                label="Datum"
                v-model="datum" 
            />
            <q-input 
                outlined
                type="textarea"
                label="Text novinky" 
                v-model="novinka"
            />
            <q-btn v-if="!isPending" type="submit" style="background: #726d65; color: white" class="q-mt-lg">Uložit</q-btn>
            <q-btn v-if="isPending" disable style="background: #726d65; color: white">Ukládám data</q-btn>



        </form>
    </div>
</template>

<script>
import { ref } from 'vue'
import { db } from '@/firebase/config'
import { collection, addDoc, Timestamp } from 'firebase/firestore'

export default {
    setup() {

        const nadpis = ref(null)
        const datum = ref(null)
        const novinka = ref(null)
        const isPending = ref(false)

        const handleSubmit = async() => {
            isPending.value = true
            try {
                await addDoc(collection(db, 'newsHolstejn'), {
                    datum: datum.value ? Timestamp.fromDate(new Date(datum.value)) : null,
                    nadpis: nadpis.value,
                    novinka: novinka.value,
                })
                //togglePotvrzeni()
                isPending.value = false
                formToNull()
            } catch (error) {
                console.error('Error during data submission:', error)
            }
        }

        const formToNull = () => {
            nadpis.value = null
            datum.value = null
            novinka.value = null
        }



        return {
            nadpis, datum, novinka, isPending,
            handleSubmit
        }
    }

}
</script>

<style>

</style>
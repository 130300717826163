<template>
      <CeleMenu />

<!--tahle část je vlastní obsah routy-->
    <div class="row justify-around" style="height: 65vh; overflow: auto;">
        <div class="col-12 q-pa-xl">         
            <div v-for="trasa in startovka" :key="trasa">
                <StartList 
                    :listina="trasa"
                />
            </div>
        </div>
    </div>
  <div class="col-12 self-end footer item" style="height: 5vh;">
    <FooterSection />
  </div>


</template>

<script>

import CeleMenu from '@/components/CeleMenu.vue'
import FooterSection from '@/components/FooterSection.vue'
import StartList from '@/components/StartList.vue'


import { useMeta } from 'vue-meta'



export default {
    components: {CeleMenu, FooterSection, StartList}, 
    setup() {

        useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - startovka' })

       const startovka = [
        {nadpis: 'Startovní listina - LONG', trasa: 'long'},
        {nadpis: 'Startovní listina - MID', trasa: 'mid'},
        {nadpis: 'Startovní listina - SHORT', trasa: 'vycházka(short)'}
       ]

        return { 
            startovka

         }

    }



}
</script>

<style>

</style>
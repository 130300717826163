<template>
    <div class="q-mt-md">
        <!--editační formulář-->
        <form  @submit.prevent="handleSubmit" 
            class="q-gutter-sm"
            shouldFocus: true
            >
            <q-input 
                outlined
                type="text"
                label="Nadpis"
                v-model="nadpis" 
            />
            <q-input 
                outlined
                type="date"
                label="Datum"
                v-model="datum" 
            />
            <q-input 
                outlined
                type="textarea"
                label="Text novinky" 
                v-model="newNovinka"
            />
            <q-btn v-if="!isPending" type="submit" style="background: #726d65; color: white" class="q-mt-lg">Uložit změny</q-btn>
            <q-btn v-if="isPending" disable style="background: #726d65; color: white">Ukládám data</q-btn>

        </form>
    </div>  
</template>

<script>
import { ref } from 'vue'
import { format } from 'date-fns'
import { useRouter } from 'vue-router'

import { db } from '@/firebase/config'
import { updateDoc, doc, Timestamp } from 'firebase/firestore'


export default {
    props: [ 'novinka', 'id' ],
    setup(props) {
        const nadpis = ref(props.novinka.nadpis)
        const datum = ref(format(new Date(props.novinka.datum.seconds * 1000 + props.novinka.datum.nanoseconds / 1e6), 'yyyy-MM-dd'))
        const newNovinka = ref(props.novinka.novinka)
        const isPending = ref(false)

        const router = useRouter()

        const handleSubmit = async() => {
            isPending.value = true
            const docRef = doc(db, 'newsHolstejn', props.id)
            const newData = {
                datum: datum.value ? Timestamp.fromDate(new Date(datum.value)) : null,
                nadpis: nadpis.value,
                novinka: newNovinka.value,
            }
                try {
                    updateDoc(docRef, newData)
                } catch {
                    showChybu.value = true
                }
            isPending.value = false
            router.push('/admin/news')
        }


        return {
            nadpis, datum, newNovinka,
            isPending,
            handleSubmit,
        }

    }
}
</script>

<style>

</style>
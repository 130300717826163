<template>

  <CeleMenu />

  <!--tahle část je vlastní obsah routy-->
  <div class="row justify-around" style="height: 65vh; overflow: auto;">
    <div class="col-12 col-md-6 q-pa-xl">
      <h3>Legenda:</h3> 
      <p>Vok IV. žil bouřlivým životem, často byl před soudem žalován z loupeživých nájezdů, neplacení dluhů a 
        přivlastňování si cizích statků. V roce 1415 podepsal a pečetí opatřil svůj protest proti upálení Jana Husa a 
        byl přívrženec husitství, bohužel jen do roku 1419, kdy se přiklonil k Zikmundovi. Spolu s jeho vojskem pak táhl 
        na husitskou Prahu. Při dobývání Vyšehradu byla armáda Voka IV. rozdrcena husity a sám Vok IV. tu nalezl smrt. 
        Je pohřben na hřbitově u kostela sv. Pankráce.</p>
      <p>Hrad Holštejn se za jeho života stal základnou rytířského loupení, v hladomorně pod hradem 
        tehdy zemřela spousta lidí. Jeho loupeživé řádění dalo za vznik spoustě pověstí o obrovském pokladu, 
        který je v okolí ukryt.</p>
    </div>
    <div class="col-12 col-md-5 q-pa-xl">
      <div class="q-pa-sm shadow-3" style="background-color: #cfc3b4">
        <q-img
          src="@/assets/little-girl-blowing-wind-mill.jpg"
          spinner-color="secondary"
          class="rounded-borders"
        />
      </div>
    </div>
    <div class="col-11">
      <div class="row justify-around rounded-borders q-pa-xs content-center items-center" style="border: 1px solid #726d65; ">
        <div class="col-11 col-md-3">
          <p>Závod je zařazen do seriálu MČR v dogtrekkingu</p>
          <a href="https://dogtrekking.info/terminovka.htm" target="_blank">
            <q-img
              src="@/assets/dogtrek.jpg"
              spinner-color="secondary"
              class="rounded-borders shadow-3"
            />
          </a>
        </div>
        <div class="col-11 col-md-3">
          <p>Závod je zařazen do seriálu MČR v dogmidu</p>
            <a href="http://dogmid.cz" target="_blank">
              <q-img
                src="@/assets/dogmid.jpg"
                spinner-color="secondary"
                class="rounded-borders shadow-3"
              />
            </a>
        </div>
      </div>
      <div class="row justify-center q-gutter-x-xs q-pa-md">
        <div class="col-12" style="text-align: center;"> Konání akce umožnil podnik Lesy České republiky, s. p.</div>        
        <q-img
          src="@/assets/lesy2.png"
          spinner-color="secondary"
          class="col-1"
        />


      </div>
    </div>
  </div>

  <div class="col-12 self-end footer item" style="height: 5vh;">
    <FooterSection />
  </div>


</template>

<script>

import CeleMenu from '@/components/CeleMenu.vue'
import FooterSection from '@/components/FooterSection.vue'

import { useMeta } from 'vue-meta'


export default {
  components: {CeleMenu, FooterSection},

  setup(){
    useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - úvodní stránka' })

  }

}
</script>

<template>
    <div class="row justify-center content-center" style="height: 100%">
        <div class="col-12 col-sm-8">
            <h4>Přihlášení do administrace</h4>
        </div>
        <div class="login q-ma-xl q-pa-xl col-12 col-sm-8 items-center shadow-3">
            <form 
                @submit.prevent="handleSubmit" 
                class="row q-gutter-xl content-center" 
                style="height: 30vh"
                >
                <q-input 
                    outlined
                    type="text"
                    label="email"
                    class="col-10"
                    v-model="email"
                    autocomplete="email"
                />
                <q-input 
                    outlined
                    type="password"
                    label="heslo"
                    class="col-10"
                    v-model="password"
                    autocomplete="current-password"
                />     
                <div v-if="error">{{ error }}</div>     
                <div v-if="!isPending">      
                    <q-btn type="submit" style="background: #726d65; color: white">Přihlásit</q-btn>
                </div>
                <div v-else>      
                    <q-btn disable type="submit" style="background: #726d65; color: white">Probíhá přihlašování</q-btn>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useLogin from '@/composables/useLogin'

export default {
  setup() {
    const { login, error, isPending } = useLogin()
    const router = useRouter()

    const email = ref('')
    const password = ref('')

    const handleSubmit = async () => {
      await login(email.value, password.value)

      
        //nezapomenout přidat vykopnutí po logoutu
        if(!error.value) {
        router.push('/admin/main')
      }
    }

    return { email, password, handleSubmit, error, isPending }
  }
}</script>

<style>

</style>
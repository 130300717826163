<template>

  <q-carousel
      animated
      v-model="slide"
      infinite
      :autoplay="autoplay"
      arrows
      transition-prev="slide-right"
      transition-next="slide-left"
      @mouseenter="autoplay = false"
      @mouseleave="autoplay = true"
      height="25vh"
    >
      <q-carousel-slide :name="1" img-src="@/assets/carousel/5.jpg" />
      <q-carousel-slide :name="2" img-src="@/assets/carousel/18.jpg"  />
      <q-carousel-slide :name="3" img-src="@/assets/carousel/22.jpg"  />
      <q-carousel-slide :name="4" img-src="@/assets/carousel/33.jpg"  />
      <q-carousel-slide :name="5" img-src="@/assets/carousel/11.jpg"  />
      <q-carousel-slide :name="6" img-src="@/assets/carousel/35.jpg"  />
      <q-carousel-slide :name="7" img-src="@/assets/carousel/26.jpg"  />
      <q-carousel-slide :name="8" img-src="@/assets/carousel/34.jpg"  />

    </q-carousel>

</template>

<script>
import { ref } from 'vue';

export default {
    setup(){
        
        
        return {
        slide: ref(1),
        autoplay: ref(true)
        }
    }
}
</script>

<style>

</style>
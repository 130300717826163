import { defineStore } from 'pinia'
import { storage } from "@/firebase/config.js"
import {ref as firebaseRef, getDownloadURL, listAll } from 'firebase/storage'


export const useGallery = defineStore('gallery', {
  state: () => {
    return { 
        galleries: [
          {
              title: 'Fotky z trasování 2024',
              cesta: 'trasovani24',
              storage: '/Trasovani24',
          }

        ],
        obrazky: []


     }
  },

  getters: {
    //získání jedné galerie podle předané param z adresního řádku
    getGalleryDetail: (state) => {
      return (cesta) => {
        return state.galleries.filter(gallery => {return gallery.cesta === cesta})[0]
      }
    }


  },


  actions: {
    //získání názvu obrázků a podle nich i adres
    async listImages (imagesRef) {
      const res = await listAll(imagesRef)
      res.items.forEach(async(itemRef) => {
        const spaceRef = firebaseRef(imagesRef, itemRef.name)
        const url = await getDownloadURL(spaceRef)
        this.obrazky.push(url)
      })
    }



  },






})
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8zmMgAl2SPKrEPJjMhZZUs2SuGOJYyjA",
  authDomain: "dogtrek-holstejn.firebaseapp.com",
  projectId: "dogtrek-holstejn",
  storageBucket: "dogtrek-holstejn.appspot.com",
  messagingSenderId: "575972244867",
  appId: "1:575972244867:web:c35d1b665d4d6e085c8ab6",
  measurementId: "G-2D2WNZL337"
}


// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const db = getFirestore()
const auth = getAuth()
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdI-egoAAAAAL7YFKGZ3lHTV44YUlFPEVHR6SOv'),
  isTokenAutoRefreshEnabled: true
  })
const storage = getStorage(app)


export { db, app, analytics, appCheck, auth, storage }


<template>
    <CeleMenu />

    <!--tahle část je vlastní obsah routy-->
    <div class="row justify-around" style="height: 65vh; overflow: auto;">
        <div class="col-12 col-md-11 q-pa-xl" style="text-align: center;">
            <h3>{{detail.title}}</h3>
            <div class="row">
                <div v-for="OneSponzor in AllSponzors" :key="OneSponzor" class="col-12 col-md-6 q-pa-lg">
                    <div v-if="OneSponzor.text">
                        {{ OneSponzor.text }}
                    </div>
                    <div v-if="OneSponzor.odkaz">
                        <a :href=OneSponzor.odkaz target="_blank">
                            <q-img :src=OneSponzor.web />
                        </a>
                    </div>
                    <div v-else>
                        <q-img :src=OneSponzor.web />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 self-end footer item" style="height: 5vh;">
        <FooterSection />
    </div>

</template>

<script setup>

//imports            

    import CeleMenu from '@/components/CeleMenu.vue'
    import FooterSection from '@/components/FooterSection.vue'
    import { useRoute } from 'vue-router'
    import { useMeta } from 'vue-meta'
    import { useSponzor } from '@/stores/useSponzor'
    import { ref } from 'vue'
    import { storage } from "@/firebase/config.js"
    import {ref as firebaseRef } from 'firebase/storage'


// SEO
    useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - sponzoři' })

// zadání storu
    const Sponzor = useSponzor()

// route
    const route = useRoute()

// zadání props
    const props = defineProps(['cesta'])

// získání hlavního přehledu galerie
    const detail = ref(null)
    detail.value = Sponzor.getGalleryDetail(route.params.cesta)

// získání sponzorů
    const AllSponzors = ref([])
    
// složka ve storage
  const imagesRef = firebaseRef(storage, detail.value.storage)

// Funkce pro načtení sponzorů
    const loadSponzors = async() => {
        const sponzors = await Sponzor.getSponsors(route.params.cesta)
        AllSponzors.value = sponzors

        // Pro každý sponzor zavolejte funkci getAdress a aktualizujte adresu obrázku
        for (let i = 0; i < AllSponzors.value.length; i++) {
            const sponzor = AllSponzors.value[i]
            const imageUrl = await Sponzor.getAdress(imagesRef, sponzor.obrazek)
            if (imageUrl) {
            // Pokud se podaří získat adresu obrázku, aktualizujte objekt sponzora
                sponzor.web = imageUrl
            }
        }
    }

// Načtěte sponzory při inicializaci komponenty nebo jinak, kde je to vhodné pro váš projekt
loadSponzors()


</script>


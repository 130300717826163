<template>
      <div v-if="!isPending">
        <q-table
            :rows="zavodnik"
            :columns="columns"
            row-key="name"
            :rows-per-page= "zavodnik.length"
            :rows-per-page-options="[zavodnik.length, 5, 10, 20]"

            :rows-per-page-label="'Řádků na stránku'"

        >

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"

                    >
                        <span v-if="col.name === 'zaplaceno'">
                            <q-icon 
                                :name="props.row.zaplaceno ? 'check' : 'close'" 
                                :color="props.row.zaplaceno ? 'positive' : 'negative'" 
                                @click = "showModalPlatby(props.row.zaplacenoCastka, props.row.zaplacenoDate, props.row.telefon)"
                                />
                            <q-tooltip 
                                v-if="!props.row.zaplaceno" 
                                anchor="center left" 
                                self="center right"
                                class="bg-primary text-body2"
                                >
                                Kliknutím můžete změnit<br> status platby na zaplaceno
                            </q-tooltip>
                        </span>

                        
                        <!--poznámka se zobrazením textu poznámky-->
                        <span v-else-if="col.name === 'poznamka'" >
                            <q-icon :name="props.row.poznamka ? 'check' : 'close'" :color="props.row.poznamka ? 'positive' : 'negative'" />
                            <q-tooltip 
                                v-if="props.row.poznamka" 
                                anchor="center left" 
                                self="center right"
                                class="bg-primary text-body2"
                                >
                                {{props.row.poznamka}}
                            </q-tooltip>
                        </span>

                        <!--                        
                        <span v-else-if="col.name === 'pracovka'" v-html="col.format(props.row.pracovka)">
                                        
                        </span>
                        <span v-else-if="col.name === 'voditka'">
                            <q-icon  
                                :name="props.row.poznVyrizena ? 'check' : 'close'" 
                                :color="props.row.poznVyrizena ? 'positive' : 'negative'" 
                                @click = handlePoznamka(props.row.telefon)
                            />
                            <q-tooltip 
                                v-if="props.row.poznamka && !props.row.poznVyrizena" 
                                anchor="center left" 
                                self="center right"
                                class="bg-primary text-body2"
                                >
                                Kliknutím změníte status poznámky na vyřízenou
                            </q-tooltip>
                        </span>-->
                        
                        <!---->

                        <span v-else-if="col.name === 'poznVyrizena'">
                            <q-icon  
                                :name="props.row.poznVyrizena ? 'check' : 'close'" 
                                :color="props.row.poznVyrizena ? 'positive' : 'negative'" 
                                @click = handlePoznamka(props.row.telefon)
                            />
                            <q-tooltip 
                                v-if="props.row.poznamka && !props.row.poznVyrizena" 
                                anchor="center left" 
                                self="center right"
                                class="bg-primary text-body2"
                                >
                                Kliknutím změníte status poznámky na vyřízenou
                            </q-tooltip>
                        </span>
                        <span v-else-if="col.name === 'editace'">
                            <router-link :to="{name: 'admineditace', params: {id: props.row.id}}">
                                <q-btn label="Upravit" />
                            </router-link>
                        </span>
                        <span v-else-if="col.name === 'zobrazeni'">
                            <router-link :to="{name: 'adminshow', params: {id: props.row.id}}">
                                <q-btn label="Zobrazit" />
                            </router-link>
                        </span>

                        <span v-else>{{ col.value }}</span>                    

                    </q-td>

                </q-tr>
            </template>
        </q-table>
    </div>
    <div v-else>
        <div class="q-pa-md">
            <q-linear-progress query />
            <q-linear-progress query color="primary" class="q-mt-sm" />
            <q-linear-progress query color="secondary" class="q-mt-sm" />
            <q-linear-progress query color="accent" class="q-mt-sm" />
            <q-linear-progress query color="primary" class="q-mt-sm" />
            <q-linear-progress query color="secondary" class="q-mt-sm" />
            <q-linear-progress query color="accent" class="q-mt-sm" />
        </div>
    </div>
    <div v-if="modalPlatby" class = "col-12" style="height: 100vh; position: relative; z-index: 800; align-content: center; align-items: center;">
        <PotvrzeniPrihlaseni 
            @toggleDotaz = "modalPlatby = false"
            theme = "warning"
            >
            <p>potvrzení platby</p>
            <q-input 
                    outlined
                    type="number"
                    label="placena částka"
                    v-model="castka" 
                />
                <q-input 
                    outlined
                    type="date"
                    label="Datum platby"
                    v-model="date" 
                />

            <div class="q-pa-md q-gutter-sm">
                <q-btn @click="savePlatba()" style="background: #f5f3f1; color: #726d65" size="md">uložit platbu</q-btn>
            </div>
        </PotvrzeniPrihlaseni>
    </div>

</template>

<script>
import PotvrzeniPrihlaseni from '@/components/Potvrzeni.vue'


import { ref } from 'vue'
import { db } from '@/firebase/config'
import { updateDoc, serverTimestamp, doc, Timestamp } from 'firebase/firestore'
import { format } from 'date-fns'



export default {
    components: { PotvrzeniPrihlaseni },
    props: [ 'isPending', 'zavodnik' ],
    setup(props) {

        const columns = [

            {
                label: 'jméno',
                name: 'jmeno',
                required: true,
                align: 'left',
                field: row => row.jmeno,
                format: val => `${val}`,
                sortable: true
            },
            { name: 'prijmeni', align: 'center', label: 'příjmení', field:row => row.prijmeni, sortable: true },
            { name: 'telefon', align: 'center', label: 'telefon', field: row => row.telefon, sortable: true},
            { name: 'email', align: 'center', label: 'email', field: row => row.email, sortable: true},
            { name: 'trasa', align: 'center', label: 'trasa', field: row => row.trasa, sortable: true},
            { name: 'kategorie', align: 'center', label: 'kategorie', field: row => row.kategorie, sortable: true},
            {
                label: 'pracovka',
                name: 'pracovka',
                align: 'center',
                field: row => row.psi.some(pes => pes.pracovka === 'ano'), // Zkontroluje, zda alespoň jeden pes má pracovka: 'ano'
                format: val => val ? '✔️' : '✖️', //'<q-icon name="check" color="positive"></q-icon>' : '<q-icon name="close" color="negative"></q-icon>',
                sortable: true // Nastavení podle potřeby
            },
            { name: 'voditka', align: 'center', label: 'vodítka (ks)', field: row => `${row.voditkoMale} + ${row.voditkoVelke}`, sortable: true},
            { name: 'startovne', align: 'center', label: 'k úhradě', field: row => `${row. startovne + row.voditkoMale * 270 + row.voditkoVelke*350}`, sortable: true},
            { name: 'zaplaceno', align: 'center', label: 'zaplaceno', field: row => row.zaplaceno, sortable: true},
            // { name: 'psu', align: 'center', label: 'psů', field: row => row.pocetPsu, sortable: true},
            { name: 'poznamka', align: 'center', label: 'poznámka', field: row => row.poznamka, sortable: true},
            { name: 'poznVyrizena', align: 'center', label: 'poznámka vyřízena', field: row => row.poznVyrizena, sortable: true},
            { name: 'editace', align: 'center', label: 'editace' },
            { name: 'zobrazeni', align: 'center', label: 'zobrazeni' },


        ]


        
        const handlePoznamka = (id) => {
            const docRef = doc(db, 'zavodniciDtHolstejn', 'DT2024', 'private', id)
            const privateData = {
                poznVyrizena: true,
                poznamkaVyrizenaDate: serverTimestamp()
            }

            try {
                updateDoc(docRef, privateData)
            } catch {
                console.log('změna dat selhala')
            }

        }

        const castka = ref(null)
        const date = ref(null)
        const telefon = ref(null)
        const modalPlatby = ref(false)

        const showModalPlatby = (zaplacenoCastka, zaplacenoDate, index) => {
            modalPlatby.value = true
            castka.value = zaplacenoCastka
            if (zaplacenoDate) {
                date.value = format(new Date(zaplacenoDate.seconds * 1000 + zaplacenoDate.nanoseconds / 1e6), 'yyyy-MM-dd')
            }
            telefon.value = index
        }

        const savePlatba = () => {
            const privateRef = doc(db, 'zavodniciDtHolstejn', 'DT2024', 'private', telefon.value)
            const publicRef = doc(db, 'zavodniciDtHolstejn', 'DT2024', 'public', telefon.value)

            const privateData = {
                zaplacenoCastka: castka.value,
                zaplaceno: true,
                zaplacenoDate: Timestamp.fromDate(new Date(date.value))
            }
            const publicData = {
                zaplaceno: true,
            }

            try {
                console.log('z tabulky: privateRef: ', privateRef)
                console.log('z tabulky: privateData: ', privateData)

                updateDoc(privateRef, privateData)
                updateDoc(publicRef, publicData)

            } catch {
                console.log('změna dat selhala')
            }

            castka.value = null
            date.value = null
            telefon.value = null
            modalPlatby.value = false
        }



        return {
            columns,
            handlePoznamka,
            modalPlatby, showModalPlatby, castka, date, savePlatba
        }

    }
}
</script>

<style>

</style>
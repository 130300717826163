import { ref, watchEffect } from 'vue'

// firebase imports
import { db } from '../firebase/config'
import { collection, onSnapshot, query, where, orderBy } from 'firebase/firestore'

/*
  const collectionRef = collection(db, 'dtHolstejn2024')
  //Získání všech dokumentů z subkolekce 'private'
  const querySnapshot = await getDocs(collection(collectionRef, 'private'))*/

const getSubcollection = (c, q, o) => {
  console.log('voláno getSubcollection s následujícími údaji: c: ', c, 'q: ', q, 'o: ', o)
  const documents = ref(null)


  // collection reference
  let colRef = collection(db, ...c)

  if (q || o) {
    // Vytvoření dotazu s možností přidání where a orderBy podle podmínek
    let queryRef = colRef;
    
    if (q) {
      queryRef = query(queryRef, where(...q))
    }

    if (o) {
      queryRef = query(queryRef, orderBy(o))
    }
    colRef = queryRef;
  }

  const unsub = onSnapshot(colRef, snapshot => {
    let results = []
    snapshot.docs.forEach(doc => {
      results.push({ ...doc.data(), id: doc.id })
    })
    
    // update values
    documents.value = results
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  })

  return { documents }
}

export default getSubcollection
import { ref, watchEffect } from 'vue'

// firebase imports
import { db } from '../firebase/config'
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore'

const getCollection = (c, o) => {
  const documents = ref(null)

  // collection reference
  let colRef = collection(db, ...c)

  if (o) {
      colRef = query(colRef, orderBy(...o))
  }

  const unsub = onSnapshot(colRef, snapshot => {
    let results = []
    snapshot.docs.forEach(doc => {
      results.push({ ...doc.data(), id: doc.id })
    })
    
    // update values
    documents.value = results
    console.log('documents.value: ', documents.value)
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  })

  return { documents }
}

export default getCollection
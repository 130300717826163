<template>

  <q-carousel
      animated
      v-model="slide"
      infinite
      :autoplay="autoplay"
      arrows
      transition-prev="slide-right"
      transition-next="slide-left"
      @mouseenter="autoplay = false"
      @mouseleave="autoplay = true"
      height="25vh"
      class="carousel"
    >
    <q-carousel-slide :name="1" class="column no-wrap">
      <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/5.jpg" />
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/6.jpg" />
      </div>
    </q-carousel-slide>
    <q-carousel-slide :name="2" class="column no-wrap">
      <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/11.jpg" />
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/12.jpg" />
      </div>
    </q-carousel-slide>
    <q-carousel-slide :name="3" class="column no-wrap">
      <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/21.jpg" />
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/26.jpg" />
      </div>
    </q-carousel-slide>
    <q-carousel-slide :name="4" class="column no-wrap">
      <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/30.jpg" />
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/36.jpg" />
      </div>
    </q-carousel-slide>
    <q-carousel-slide :name="5" class="column no-wrap">
      <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/33.jpg" />
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/34.jpg" />
      </div>
    </q-carousel-slide>
    <q-carousel-slide :name="6" class="column no-wrap">
      <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/28.jpg" />
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/25.jpg" />
      </div>
    </q-carousel-slide>
    <q-carousel-slide :name="7" class="column no-wrap">
      <div class="row fit justify-start items-center q-gutter-xs q-col-gutter no-wrap">
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/39.jpg" />
        <q-img class="rounded-borders shadow-2 col-6 full-height" src="@/assets/carousel/35.jpg" />
      </div>
    </q-carousel-slide>

    </q-carousel>

</template>

<script>
import { ref } from 'vue';

export default {
    setup(){
        
        
        return {
        slide: ref(1),
        autoplay: ref(true)
        }
    }
}
</script>

<style>

</style>
<template>
      <CeleMenu />

<!--tahle část je vlastní obsah routy-->
<div class="row justify-around" style="height: 65vh; overflow: auto;">
  <div class="col-11 q-pa-xl">
    <div v-if="novinky">
      <div v-for="(newItem, index) in novinky" :key="index">
        <Novinka 
          :newItem="newItem"  
        />
      </div>
    </div>
  </div>
  <!--
       
    
    <h5>22. 11. 2023</h5>
    <p>Spouštíme v testovacím režimu nový web. Když narazíte na chybu, budeme rádi, když nám o ní dáte vědět.</p>
    <p>Na doméně, kterou znáte, najdete stále starou verzi webu. Aktualizace proběhne, až se přesvědčíme, že všechno funguje tak, jak má :)</p>
    
    <br>
    <h5>4. 10. 2023</h5> 
      <p>Domluvili jsme se, že v roce 2024 bude opět Vok!</p>
      <p>Pořadatelský tým skupuje všechen dostupný alkohol, aby to zvládl přežít, zvažuje trasy a začíná sestavovat nové webovky.</p>


  </div>-->
</div>
  <div class="col-12 self-end footer item" style="height: 5vh;">
    <FooterSection />
  </div>


</template>

<script>

import getCollection from '@/composables/getCollection'


import CeleMenu from '@/components/CeleMenu.vue'
import FooterSection from '@/components/FooterSection.vue'
import Novinka from '@/components/Novinka.vue'


import { useMeta } from 'vue-meta'


export default {
  components: {CeleMenu, FooterSection, Novinka},
  setup(){
    useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - novinky' })


    const { documents: novinky } = getCollection(
      ['newsHolstejn'],
      ["datum", "desc"]
      )


      
      return {
      novinky
      }


    }


}
</script>

<style>

</style>
import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { createMetaManager } from 'vue-meta'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { auth } from './firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

let app

onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App)
      .use(createPinia())
      .use(Quasar, quasarUserOptions)
      .use(router).use(createMetaManager())
      .use(VueReCaptcha, { siteKey: '6LdI-egoAAAAAL7YFKGZ3lHTV44YUlFPEVHR6SOv' })
      .mount('#app')
    }
})

<template>
    <div v-if="novinky" class="q-mt-xl row">
        <q-table
            :rows="novinky"
            :columns="columns"
            row-key="nadpis"
            :rows-per-page= 10
            :rows-per-page-options="[ 5, 10, 20]"
            :rows-per-page-label="'Řádků na stránku'"
            class="col-12 col-md-10 col-lg-7"
        >
        
            <template v-slot:body="props">

                <q-tr :props="props">
                    <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        <span v-if="col.name === 'editace'">
                            <router-link :to="{name: 'adminEditNew', params: {id: props.row.id}}">
                                <q-btn label="Upravit" />
                            </router-link>
                        </span>
                        <span v-else>{{ col.value }}</span>                    

                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>

    <div v-else>
        <div class="q-pa-md q-mt-xl">
            <q-linear-progress query />
            <q-linear-progress query color="primary" class="q-mt-sm" />
            <q-linear-progress query color="secondary" class="q-mt-sm" />
            <q-linear-progress query color="accent" class="q-mt-sm" />
            <q-linear-progress query color="primary" class="q-mt-sm" />
            <q-linear-progress query color="secondary" class="q-mt-sm" />
            <q-linear-progress query color="accent" class="q-mt-sm" />
        </div>
    </div>

</template>

<script>
import getCollection from '@/composables/getCollection'


import { ref } from 'vue'
import { format } from 'date-fns'


export default {
    setup() {

        const isPending = ref(true)

        const { documents: novinky } = getCollection(
        ['newsHolstejn'],
        ["datum", "desc"]
        )

        //zvaž jestli neudělat z nadpisu snippet pro případ, že by byl někde moc dlouhej
        const columns = [
            { name: 'datum', align: 'center', label: 'datum', field: row => timestampToString(row.datum), sortable: true},
            { name: 'nadpis', align: 'center', label: 'nadpis', field:row => row.nadpis, sortable: true },
            { name: 'editace', align: 'center', label: 'editace' },

            ]

        const timestampToString = (timestamp) => {
            return format(new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6), 'dd. MM. yyyy')
        }


        return {
        novinky, columns, isPending
        }

    }


}
</script>

<style>

</style>
<template>
    <div class="non-selectable">
    <q-btn icon="menu" style="color: white;" size="lg" flat>
      <q-menu anchor="center right" self="top middle" class="lt-sm">
        <q-list dense style="min-width: 100px">
            <q-item clickable v-for="(item, index) in menuItems" :key="index" class="non-selectable cursor-pointer">
            <q-item-section v-if="!item.children">
                <router-link :to="{ name: item.route}">{{ item.name }}</router-link>
            </q-item-section>
            <q-item-section v-else>
                {{ item.name }}
            </q-item-section>
            <q-item-section v-if="item.children" side><q-icon name="keyboard_arrow_right" /></q-item-section>
            <q-menu anchor="center right" self="top middle" class="lt-sm">
              <q-list dense style="min-width: 100px">
                <q-item clickable v-for="(level1Item, index) in item.children" :key="index" class="non-selectable cursor-pointer">
                    <q-item-section v-if="!level1Item.children" >
                      <router-link v-if="level1Item.param" :to="{ name: level1Item.route, params: { cesta: level1Item.param }}">
                            {{ level1Item.name }}
                        </router-link>
                        <router-link v-else :to="{ name: level1Item.route}">
                            {{ level1Item.name }}
                        </router-link>
                    </q-item-section>
                    <q-item-section v-else>
                        {{ level1Item.name }}
                    </q-item-section>                  
                    <q-item-section v-if="level1Item.children" side><q-icon name="keyboard_arrow_right" /></q-item-section>
                  <q-menu anchor="center right" self="top middle" auto-close class="lt-sm">
                    <q-list dense>
                      <q-item
                      v-for="(level2Item, name) in level1Item.children"
                        :key="name"
                        clickable
                      >
                        <router-link :to="{ name: level2Item.route}">
                            <q-item-section>{{ level2Item.name }}</q-item-section>
                        </router-link>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-item>
              </q-list>
            </q-menu>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </div>   

</template>

<script>
export default {
    props: ['menuItems']


}
</script>

<style>

</style>
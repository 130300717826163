<template>
    <div class="cursor-pointer non-selectable">
        <q-btn v-for="(item, index) in menuItems" :key="index" flat size="md">
            <router-link v-if="!item.children" :to="{ name: item.route}">
                {{ item.name }}
            </router-link>
            <span v-else>
                {{ item.name }}
            </span>
            <q-menu v-if="item.children" class="gt-xs">
                <q-list dense style="min-width: 100px">
                    <q-item clickable v-for="(level1Item, index) in item.children" :key="index" class="cursor-pointer">
                        <q-item-section v-if="!level1Item.children" >
                            <router-link v-if="level1Item.param" :to="{ name: level1Item.route, params: { cesta: level1Item.param }}">
                            {{ level1Item.name }}
                        </router-link>
                        <router-link v-else :to="{ name: level1Item.route}">
                            {{ level1Item.name }}
                        </router-link>
                        </q-item-section>
                        <q-item-section v-else>
                                {{ level1Item.name }}
                        </q-item-section>
                        <q-item-section v-if="level1Item.children" side><q-icon name="keyboard_arrow_right" /></q-item-section>
                            <q-menu anchor="top end" self="top start" auto-close class="gt-xs">
                            <q-list dense>
                                <q-item
                                v-for="(level2Item, name) in level1Item.children"
                                :key="name"
                                clickable
                                >
                                    <q-item-section>
                                        <router-link :to="{ name: level2Item.route}">
                                            {{ level2Item.name }}
                                        </router-link>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-menu>
                    </q-item>
                </q-list>
            </q-menu>
        </q-btn>
    </div>
  <q-space />

</template>

<script>
export default {
    props: ['menuItems']

}
</script>

<style>

</style>
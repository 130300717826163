<template>
  <!--
    zvaž jestli nedat přecijen základní vzhled do app.vue 
    celej kód bude hezčí a jednodušší
    ale ošklivý to bude v administraci, kde bude stejný meníčko a patička jako na webu....
  -->

      <CeleMenu />

      <!--tahle část je vlastní obsah routy-->
      <div class="row justify-around" style="height: 65vh; overflow: auto;">
        <div class="col-12 col-md-6 q-pa-xl">
          <h4>Základna</h4> 
          <p><a href="https://hostinecpodhradem.webnode.cz/" target="_blank"><q-icon name="open_in_new"/> Hostinec pod hradem Holštejn</a></p>
          <p>Holštejn 1</p>
          <p>679 13</p>
          <q-separator color="secondary" inset />
          <div class="q-pt-xl">
            <h4>Pořadatel</h4>
            <p><a href="https://kkadamov.webnode.cz/" target="_blank"><q-icon name="open_in_new"/> KK Adamov</a></p>
          </div>

          <q-separator color="secondary" inset />
          <div class="q-pt-xl">
            <h4>Kontakt</h4>
            <p>Petr Blažek</p>
            <p>email: dogtrekking.holstejn@gmail.com</p>
          </div>
        </div>
        <div class="col-12 col-md-5 q-pa-xl">
          <div class="q-pa-sm shadow-3" style="background-color: #cfc3b4">
            <q-img
              src="@/assets/little-girl-blowing-wind-mill.jpg"
              spinner-color="secondary"
              class="rounded-borders"
            />
          </div>
        </div>
      </div>
        <div class="col-12 self-end footer item" style="height: 5vh;">
          <FooterSection />
        </div>


</template>

<script>

import CeleMenu from '@/components/CeleMenu.vue'
import FooterSection from '@/components/FooterSection.vue'



import { useMeta } from 'vue-meta'



export default {
  components: {CeleMenu, FooterSection },
  setup(){
    useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - kontaktní údaje' })

  }


}
</script>

<template>
  <div  class="q-pt-md">
    <h4>{{ newItem.nadpis }}</h4>
    <span>{{ datum }}</span>
    <div v-html="newItem.novinka" >
    </div> 


  </div>
</template>

<script>
import { format } from 'date-fns'


export default {
    props: ['newItem'],
    setup(props) {

        

        //zvaž jestli neudělat z nadpisu snippet pro případ, že by byl někde moc dlouhej
        const timestampToString = (timestamp) => {
            return format(new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6), 'dd. MM. yyyy')
        }

        const datum = timestampToString(props.newItem.datum)

        return {
            datum
        }

    }


}
</script>

<style>

</style>
<template>
    <div>
        <b>PLYŠÁK se jmenuje stejně pro Long i Mid - pro něj doporučuju vyplnit obě pole, jinak je to jedno</b>
    </div>
    <form  @submit.prevent="handleSubmit" 
        class="q-gutter-sm"
        shouldFocus: true
        >
        <q-select 
            outlined 
            v-model="zavod" 
            :options="trasa" 
            label="Délka trasy" 
        />
        <q-select 
            outlined 
            v-model="kategorie" 
            :options="kat" 
            label="Kategorie" 
        />
        <q-btn  v-if="!isPending" type="submit" style="background: #726d65; color: white">Hledat</q-btn>
        <q-btn  v-if="isPending" disable type="submit" style="background: #726d65; color: white">Hledám</q-btn>

    </form>

    <div class="q-mt-lg" v-if="nenalezen">
        <h3>žádného takového závodníka se nepovedlo najít</h3>
        Zkontroluj diakritiku a velikost písmen
    </div>
    <div class="q-mt-lg" v-if="zavodnik.length">
        <RacersTab 
            :isPending="isPending"
            :zavodnik="zavodnik"
        />
    </div>
</template>

<script>
import RacersTab from '@/components/admin/RacersTab.vue'


import queryForIds from '@/composables/queryForIds'


import { ref } from 'vue'

import { db } from '@/firebase/config'
import { collection, query, where, getDocs } from "firebase/firestore"

export default {

    components: { RacersTab },

    setup(){
        // proměnné formuláře
        const zavod = ref(null)
        const kategorie = ref(null)


        const trasa = ['long','mid', 'vycházka(short)']
        const kat = [
            'DTM 1: muži do 40-ti let včetně', 
            'DTW 1: ženy do 35-ti let včetně', 
            'DTM 2: muži nad 40 let', 
            'DTW 2: ženy nad 35 let', 
            'PLYŠÁK: všichni závodníci bez psa',
            'DMM 1: muži do 40-ti let včetně', 
            'DMW 1: ženy do 35-ti let včetně', 
            'DMM 2: muži nad 40 let', 
            'DMW 2: ženy nad 35 let',
            'vycházka(short)'
        ]


        //pro zobrazení hlášky o nenalezení závodníka
        const nenalezen = ref(false)
        //pro blokaci tlačítka během vyhledávání
        const isPending = ref(false)
        const zavodnik = ref([])


        //fce odeslání formuláře
        const handleSubmit = async() => {

            zavodnik.value = []
            //při hledání více lidí po sobě
            nenalezen.value = false
            isPending.value = true

            // adresa subkolekce jako proměnná pro vstup do hledání druhé části dat závodníka
            const adress = ref([])
            const privateAdress = ['zavodniciDtHolstejn', 'DT2024', 'private']
            adress.value = privateAdress

            // query zadání querry jako proměnné
            const q = []



            const queryRef = collection(db, 'zavodniciDtHolstejn', 'DT2024', 'public')
            let dynamicQuery = queryRef
            if (zavod.value || kategorie.value) {
                if (zavod.value) {
                    dynamicQuery = query(dynamicQuery, where('trasa', '==', zavod.value))
                }
                if (kategorie.value) {
                    dynamicQuery = query(dynamicQuery, where('kategorie', '==', kategorie.value))
                }
            }

            // Získání výsledků
            const querySnapshot = await getDocs(dynamicQuery)
            const idsFromFirstSubcollection = ref([])
            const zavodniciMap = new Map()
            if (!querySnapshot.empty) {
                // Získání dat z první subkolekce a uložení do mapy podle ID
                querySnapshot.forEach(async(doc) => {
                    const id = doc.id
                    idsFromFirstSubcollection.value.push(id)
                    zavodniciMap.set(id, { ...doc.data(), id })
                })    
                for (const item of idsFromFirstSubcollection.value) {
                    const secondSubCol = await queryForIds(adress.value, item)
                    if (!secondSubCol.empty) {
                        if (zavodniciMap.has(item)) {
                            for (const subColItem of secondSubCol) {
                                const mergedData = { ...zavodniciMap.get(item), ...subColItem.data }
                                zavodnik.value.push(mergedData)
                            }
                        }
                    } else {
                        nenalezen.value = true
                    }
                    
                }
            } else {
            nenalezen.value = true
            }                
            isPending.value = false
            zavod.value = null
            kategorie.value = null
        }



        return {
            trasa, kat,
            zavod, kategorie,
            handleSubmit,
            zavodnik, nenalezen,
            isPending,

        }
    }
}
</script>

<style>

</style>
<template>
    <div class="full-width row wrap justify-center items-center content-center" style="height:100%">

      © Zdenka Cihlářová 2023
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
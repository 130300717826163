<template>
    <div class="q-mt-md">
        <!--editační formulář-->
        <form  @submit.prevent="handleSubmit" 
            class="q-gutter-sm"
            shouldFocus: true
            >
            přihláška přijata: {{ prijetiPrihlasky }}
            <div class="row">
                <div class="col-12">
                    <h5>Kontaktní údaje</h5>
                </div>
                <q-input 
                    outlined
                    type="text"
                    label="jméno"
                    v-model="zavodnik.jmeno" 
                    class="col-12 col-md-6"
                    :class="{ 'editClass': jmenoClass }"
                />
                <q-input 
                    outlined
                    type="text"
                    label="příjmení"
                    v-model="zavodnik.prijmeni" 
                    class="col-12 col-md-6"
                    :class="{ 'editClass': prijmeniClass }"

                />
            </div>
            <div class="row">
                <q-input 
                    outlined
                    type="text"
                    label="Ulice + č.p."
                    v-model="zavodnik.ulice" 
                    class="col-12 col-md-5"
                    :class="{ 'editClass': uliceClass }"

                />
                <q-input 
                    outlined
                    type="text"
                    label="Město"
                    v-model="zavodnik.mesto" 
                    class="col-12 col-md-5"
                    :class="{ 'editClass': mestoClass }"

                />
                <q-input 
                    outlined
                    type="text"
                    label="Stát"
                    v-model="zavodnik.stat" 
                    class="col-12 col-md-2"
                    :class="{ 'editClass': statClass }"

                />
            </div>
            <div class="row">
                <q-select 
                    outlined 
                    v-model="zavodnik.predcisli" 
                    :options="pred" 
                    label="Předčíslí" 
                    class="col-3 col-sm-2 col-md-1"
                    :class="{ 'editClass': predcisliClass }"

                />
                <q-input 
                    outlined
                    type="text"
                    label="Telefon"
                    v-model="zavodnik.telefon" 
                    mask="### ### ###"
                    class="col-9 col-sm-10 col-md-5"
                    :class="{ 'editClass': telefonClass }"

                />
                <q-input 
                    outlined
                    type="text"
                    label="E-mail"
                    v-model="zavodnik.email" 
                    class="col-12 col-md-6"
                    :class="{ 'editClass': emailClass }"

                />
            </div>
            <div class="row justify-between">
                <div class="col-12">
                    <h5>Údaje o psech</h5>
                    <b>změny v této sekci se nesledují</b>
                </div>
                <div class="col-12 col-sm-2">
                    <div class="row">
                        <span style="font-size: larger;">Počet psů: {{ zavodnik.pocetPsu }}</span>
                        <!--<q-select 
                            outlined 
                            v-model="zavodnik.pocetPsu" 
                            :options="pocet" 
                            label="Počet psů" 
                            class="col-12"
                        />-->
                        <q-btn 
                            icon="add" 
                            label="Přidat psy" 
                            class="q-mt-md col-12" 
                            @click="pridatPsa"
                        />
                    </div>
                </div>
                <!--přihlášení psi-->
                <div class="col-10">
                    <div v-for="(pes, index) in zavodnik.psi" :key="index" class="row justify-end q-mb-xs">
                        <div class="col-1">
                            <div class="row justify-end">
                                <!--tlačítku zcela chybí funkcionality!-->
                                <q-btn 
                                    icon="remove" 
                                    title="Odebrat psa" 
                                    @click="odebratPsa('psi', index)"
                                />
                            </div>
                        </div>
                        <div class="col-11" >
                            <div class="row">
                                <q-input 
                                    outlined
                                    type="text"
                                    label="Jméno psa"
                                    v-model="pes.jmenoPsa" 
                                    class="col-12 col-md-6"
                                />
                                <q-input 
                                    outlined
                                    type="text"
                                    label="Plemeno psa"
                                    v-model="pes.plemenoPsa" 
                                    class="col-12 col-md-6"
                                />
                                <q-input 
                                    outlined
                                    type="date"
                                    label="Datum narození psa"
                                    v-model="pes.datumPsa" 
                                    class="col-12 col-md-6"
                                />
                                <q-input 
                                    outlined
                                    type="number"
                                    label="Číslo čipu"
                                    v-model="pes.cisloCipu" 
                                    class="col-12 col-md-6"
                                />
                                <q-select 
                                    outlined 
                                    v-model="pes.pracovka" 
                                    :options="ano_ne" 
                                    label="zápis pracovní třídy" 
                                    style="width: 100%;"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-2" v-if="noviPsi.length">
                    <b>Přidávaní psi</b>
                </div>
                <div class="col-10">
                    <div v-for="(pes, index) in noviPsi" :key="index" class="row justify-end q-mb-xs">
                        <div class="col-1">
                            <div class="row justify-end">
                                <!--tlačítku zcela chybí funkcionality!-->
                                <q-btn 
                                    icon="remove" 
                                    title="Odebrat psa" 
                                    @click="odebratPsa('noviPsi', index)"
                                />
                            </div>
                        </div>
                        <div class="col-11" >
                            <div class="row">
                                <q-input 
                                    outlined
                                    type="text"
                                    label="Jméno psa"
                                    v-model="pes.jmenoPsa" 
                                    class="col-12 col-md-6"

                                />
                                <q-input 
                                    outlined
                                    type="text"
                                    label="Plemeno psa"
                                    v-model="pes.plemenoPsa" 
                                    class="col-12 col-md-6"

                                />
                                <q-input 
                                    outlined
                                    type="date"
                                    label="Datum narození psa"
                                    v-model="pes.datumPsa" 
                                    class="col-12 col-md-6"

                                />
                                <q-input 
                                    outlined
                                    type="number"
                                    label="Číslo čipu"
                                    v-model="pes.cisloCipu" 
                                    class="col-12 col-md-6"
                                />
                                <q-select 
                                    outlined 
                                    v-model="pes.pracovka" 
                                    :options="ano_ne" 
                                    label="zápis pracovní třídy" 
                                    style="width: 100%;"
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Trať</h5>
                </div>
                <q-select 
                    outlined 
                    v-model="zavodnik.trasa" 
                    :options="trat" 
                    label="Délka trasy" 
                    class="col-12 col-md-6"
                    :class="{ 'editClass': trasaClass }"

                />
                <q-select 
                    outlined 
                    v-model="zavodnik.kategorie" 
                    :options="kat" 
                    label="Kategorie" 
                    class="col-12 col-md-6"
                    :class="{ 'editClass': kategorieClass }"

                />
            </div>
            <h5>Upomínkové předměty</h5>
            <div class="row">
                <q-select 
                    outlined 
                    v-model="zavodnik.voditkoMale" 
                    :options="pocet" 
                    label="Vodítko pro menší psy" 
                    required 
                    class="col-12 col-md-6"
                    :class="{ 'editClass': maleVoditkoClass }"
                />
                <q-select 
                    outlined 
                    v-model="zavodnik.voditkoVelke" 
                    :options="pocet" 
                    label="Vodítko pro větší psy" 
                    required 
                    class="col-12 col-md-6"
                    :class="{ 'editClass': velkeVoditkoClass }"
                />
            </div>
            <div  class="row">
                <div class="col-12">
                    <h5>Poznámka a její vyřešení</h5>
                </div>
                <div class="col-12 col-md-7 q-pa-sm" style="border: 1px solid #726d65; overflow: auto; border-radius: 4px;">
                    <b>Poznámka závodníka:</b><br>
                    {{ zavodnik.poznamka }}
                </div>
                <div class="col-12 col-md-5">
                    <div class="row">
                        <div class="col-6">
                            <q-checkbox v-model="zavodnik.poznVyrizena" label="Poznámka vyřízena" />
                        </div>
                        <q-input 
                            outlined
                            type="date"
                            label="Datum vyřízení poznámky"
                            v-model="poznamkaVyrizenaDateForm" 
                            class="col-6"
                            :class="{ 'editClass': poznVyrizenaDateClass }"

                        />
                    </div>
                    <q-input 
                        outlined
                        type="textarea"
                        rows="4"
                        label="Poznámka pořadatelů" 
                        v-model="zavodnik.poznamkaPoradatele"
                        :class="{ 'editClass': poznamkaPoradateleClass }"

                    />
                </div>
            </div>
            <div  class="row">
                <div class="col-12">
                    <h5>Platba</h5>
                </div>
                <div class="col-2">
                    <q-checkbox v-model="zavodnik.zaplaceno" label="Platba uhrazena" />
                </div>
                <q-input 
                    outlined
                    type="number"
                    label="placena částka"
                    v-model="zavodnik.zaplacenoCastka" 
                    class="col-12 col-md-5"
                    :class="{ 'editClass': zaplacenoCastkaClass }"

                />
                <q-input 
                    outlined
                    type="date"
                    label="Datum platby"
                    v-model="zaplacenoDateForm" 
                    class="col-12 col-md-5"
                    :class="{ 'editClass': zaplacenoDateClass }"

                />

            </div>
            <q-btn v-if="!isPending" @click.prevent="toggleSouhrn" style="background: #726d65; color: white" class="q-mt-lg">Uložit změny</q-btn>
            <q-btn v-if="!isPending" @click.prevent="askDeleteChanges" style="background: #726d65; color: white" class="q-mt-lg">Vymazat všechny změny změny</q-btn>
            <q-btn v-if="!isPending" @click.prevent="askDeleteRacer = true" style="background: #726d65; color: white" class="q-mt-lg">Smazat závodníka</q-btn>
            <q-btn v-if="isPending" disable style="background: #726d65; color: white">Ukládám data</q-btn>


            <!--ukládání změn-->
            <div v-if="souhrn"  class = "col-12" style="height: 100vh; position: relative; z-index: 800; align-content: center; align-items: center;">
                <PotvrzeniPrihlaseni 
                    @toggleDotaz = "toggleDotaz"
                    >
                    <p>U závodníka byly provedeny následující změny:</p>
                    <p style="font-size:medium;">(prosím myslete na to, že změny u psů krom jejich počtu tu nejsou zobrazeny)</p>
                    <div v-for="zmena in zmeny" :key="zmena">
                        {{ zmena }}
                    </div>
                    <div class="q-pa-md q-gutter-sm">
                        <q-btn type="submit" @submit="handleSubmit" style="background: #f5f3f1; color: #726d65" size="md">Uložit změny</q-btn>
                        <q-btn @click="toggleDotaz" style="background: #f5f3f1; color: black" size="md">Vrátit se</q-btn>
                    </div>
                </PotvrzeniPrihlaseni>
            </div>
            <!-- plošné mazání změn -->
            <div v-if="showDelete"  class = "col-12" style="height: 100vh; position: relative; z-index: 800; align-content: center; align-items: center;">
                <PotvrzeniPrihlaseni 
                    @toggleDotaz = "toggleDotaz"
                    theme = "warning"
                    >
                    <p>Chcete vymazat všechny provedené změny a vrátit hodnoty uložené v databázi?</p>
                    <div class="q-pa-md q-gutter-sm">
                        <q-btn @click="deleteChanges" style="background: #f5f3f1; color: #726d65" size="md">Vymazat změny</q-btn>
                        <q-btn @click="toggleDotaz" style="background: #f5f3f1; color: black" size="md">Vrátit se</q-btn>
                    </div>
                </PotvrzeniPrihlaseni>
            </div>

            <div v-if="showChybu"  class = "col-12" style="height: 100vh; position: relative; z-index: 800; align-content: center; align-items: center;">
                <PotvrzeniPrihlaseni 
                    @toggleDotaz = "showChybu = false"
                    theme = "chyba"
                    >
                    <p>při ukládání změn došlo k chybě. </p>
                    <p>Napiš to Zdence na birma@seznam.cz </p>
                </PotvrzeniPrihlaseni>
            </div>
            <div v-if="askDeleteRacer"  class = "col-12" style="height: 100vh; position: relative; z-index: 800; align-content: center; align-items: center;">
                <PotvrzeniPrihlaseni 
                    @toggleDotaz = "toggleDotaz"
                    theme = "warning"
                    >
                    <p>Opravdu chcete smazat závodníka z databáze??</p>
                    <div class="q-pa-md q-gutter-sm">
                        <q-btn @click="deleteRacer" style="background: #f5f3f1; color: #61030e" size="md">Chci smazat závodníka</q-btn>
                        <q-btn @click="toggleDotaz" style="background: #f5f3f1; color: black" size="md">Vrátit se</q-btn>
                    </div>
                </PotvrzeniPrihlaseni>
            </div>


        </form>
    </div>

</template>

<script setup>

import PotvrzeniPrihlaseni from '@/components/Potvrzeni.vue'


import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { db } from '@/firebase/config'
import { updateDoc, collection, serverTimestamp, setDoc, doc, deleteDoc } from 'firebase/firestore'
import { Timestamp } from 'firebase/firestore'


import { format } from 'date-fns'



const props = defineProps(['zavodnik'])

        
const zavodnik = ref({ ...props.zavodnik })
const originalValues = { ...props.zavodnik }


const trat = ['long','mid', 'vycházka(short)']
const pocet = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const pred = ['+420', '+421']
const kat = [
    'DTM 1: muži do 40-ti let včetně', 
    'DTW 1: ženy do 35-ti let včetně', 
    'DTM 2: muži nad 40 let', 
    'DTW 2: ženy nad 35 let', 
    'PLYŠÁK: všichni závodníci bez psa',
    'DMM 1: muži do 40-ti let včetně', 
    'DMW 1: ženy do 35-ti let včetně', 
    'DMM 2: muži nad 40 let', 
    'DMW 2: ženy nad 35 let',
    'vycházka(short)'
]
const ano_ne = ['ne', 'ano']

// vytvoření konstant pro sledování změny stavu 
const jmenoClass = ref(false)
const prijmeniClass = ref(false)
const uliceClass = ref(false)
const mestoClass = ref(false)
const statClass = ref(false)
const predcisliClass = ref(false)
const telefonClass = ref(false)
const emailClass = ref(false)
const trasaClass = ref(false)
const kategorieClass = ref(false)
const velkeVoditkoClass = ref(false)
const maleVoditkoClass = ref(false)
const poznVyrizenaDateClass = ref(false)
const poznamkaPoradateleClass = ref(false)
const zaplacenoCastkaClass = ref(false)
const zaplacenoDateClass = ref(false)

//přiřazení konstant pro sledování změny stavu k jednotlivým polím
const watchedFields = {
    jmeno: jmenoClass,
    prijmeni: prijmeniClass,
    ulice: uliceClass,
    mesto: mestoClass,
    stat: statClass,
    predcisli: predcisliClass,
    telefon: telefonClass,
    email: emailClass,
    trasa: trasaClass,
    kategorie: kategorieClass,
    voditkoVelke: velkeVoditkoClass,
    voditkoMale: maleVoditkoClass,
    poznamkaVyrizenaDate: poznVyrizenaDateClass,
    poznamkaPoradatele: poznamkaPoradateleClass,
    zaplacenoCastka: zaplacenoCastkaClass,
    zaplacenoDate: zaplacenoDateClass,
}

//sledování změn polí a úprava stavu konstant pro zobrazení upravovaných polí ve formuláři
/*
    for (const field in watchedFields) {
        const classPointer = watchedFields[field]
        watch(() => zavodnik.value[field], (newValue) => {
            if (newValue !== originalValues[field]) {
                classPointer.value = true
            } else {
                classPointer.value = false
            }
        })
    }
*/

// zjednodušení předchozího zápisu
for (const field in watchedFields) {
    const classPointer = watchedFields[field]
    watch(() => zavodnik.value[field], (newValue) => {
            classPointer.value = newValue !== originalValues[field]
    })
}

// převod data z timestampu pro data narození psů
for (let i = 0; i < zavodnik.value.psi.length; i++) {
    zavodnik.value.psi[i].datumPsa = format(new Date(zavodnik.value.psi[i].datumPsa.seconds * 1000 + zavodnik.value.psi[i].datumPsa.nanoseconds / 1e6), 'yyyy-MM-dd')
}


// odstranění jednoho ze psů - jen ze zobrazení
const odebratPsa = (pole, index) => {
    zavodnik.value.pocetPsu--
    if (pole === 'psi') {
        zavodnik.value.psi = zavodnik.value.psi.filter((_, i) => i !== index)
    } if (pole === 'noviPsi') {
        noviPsi.value = noviPsi.value.filter((_, i) => i !== index)
    } 
}


//přidávání polí pro další psy
const noviPsi = ref([])

const pridatPsa = () => {
    zavodnik.value.pocetPsu++
    noviPsi.value.push({
        jmenoPsa: '',
        plemenoPsa: '',
        datumPsa: null,
        cisloCipu: '',
    })
}


const souhrn = ref(false)
const zmeny = ref([])
const toggleSouhrn = () => {
    zmeny.value = []
    for (const key in zavodnik.value) {
        if (zavodnik.value[key] !== originalValues[key]) {
            zmeny.value.push(`${key}: ${originalValues[key]} -> ${zavodnik.value[key]}`)
        }
    }
    souhrn.value = true
}


const poznamkaVyrizenaDateForm = ref(null)
const zaplacenoDateForm = ref(null)
//převod timestamp na datum
if (zavodnik.value.zaplacenoDate ) {
    const prevodPlacenoDate = new Date(zavodnik.value.zaplacenoDate.seconds * 1000 + zavodnik.value.zaplacenoDate.nanoseconds / 1e6)
    zaplacenoDateForm.value = format(new Date(prevodPlacenoDate), 'yyyy-MM-dd')
}
if (zavodnik.value.poznamkaVyrizenaDate ) {
    const prevodPoznDate = new Date(zavodnik.value.poznamkaVyrizenaDate.seconds * 1000 + zavodnik.value.poznamkaVyrizenaDate.nanoseconds / 1e6)
    poznamkaVyrizenaDateForm.value = format(new Date(prevodPoznDate), 'yyyy-MM-dd')
}

// zavření modálů
const toggleDotaz = () => {
    souhrn.value = false
    showDelete.value = false
    askDeleteRacer.value = false
}

const showChybu = ref(false)


//úprava textu pro vyhledávání
const upravitText = (text) => {
    let upravenyText = text.trim();
    upravenyText = upravenyText.toLowerCase();
    upravenyText = upravenyText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return upravenyText;
}

const router = useRouter()
const isPending = ref(false)

// termíny uzávěrek
const prvniUzaverka = new Date('2024-08-15')
const druhaUzaverka = new Date('2024-09-15')

// výpočet startovného
const startovne = ref('')
const vypocetStartovneho = () => {
    if (originalValues.createdAt <= prvniUzaverka ) {
        if (zavodnik.value.trasa === 'long') {
            startovne.value = 500
        } else if (zavodnik.value.trasa === 'mid') {
            startovne.value = 400
        } else if (zavodnik.value.trasa === 'vycházka(short)') {
            startovne.value = 250        
        } else {
            console.log ('neznámá délka trasy')
        }
        console.log(startovne.value)
    } else if (originalValues.createdAt >= prvniUzaverka && originalValues.createdAt <= druhaUzaverka) {
        if (zavodnik.value.trasa === 'long') {
            startovne.value = 600
        } else if (zavodnik.value.trasa === 'mid') {
            startovne.value = 500
        } else if (zavodnik.value.trasa === 'vycházka(short)') {
            startovne.value = 350        
        } else {
            console.log ('neznámá délka trasy')
        }
        console.log(startovne.value)

    } else {
        console.log ('už je po druhé uzávěrce')
    }
}


//uložení změn
const handleSubmit = async() => {
    console.log('handleSubmit spuštěno')
    isPending.value = true
    souhrn.value = false
    //kompilace upravovaných a nových psů
    if (zavodnik.value.psi) {
        zavodnik.value.psi = [...zavodnik.value.psi, ...noviPsi.value]
    } else {
        zavodnik.value.psi = noviPsi.value
    }
    //zavedení adres dokumentů a datových objektů
    const docRefPublic = doc(db, 'zavodniciDtHolstejn', 'DT2024', 'public', originalValues.telefon)
    const docRefPrivate = doc(db, 'zavodniciDtHolstejn', 'DT2024', 'private', originalValues.telefon)
    vypocetStartovneho()
    const publicData = {
        jmeno: zavodnik.value.jmeno.trim(),
        prijmeni: zavodnik.value.prijmeni.trim(),
        telefon: zavodnik.value.telefon,
        psi: zavodnik.value.psi.map(pes => ({
            jmenoPsa: pes.jmenoPsa,
            plemenoPsa: pes.plemenoPsa,
            datumPsa: pes.datumPsa ? Timestamp.fromDate(new Date(pes.datumPsa)) : null,
            cisloCipu: pes.cisloCipu,
            pracovka: pes.pracovka
        })),
        trasa: zavodnik.value.trasa,
        kategorie: zavodnik.value.kategorie,
        zaplaceno: zavodnik.value.zaplaceno,
        pocetPsu: zavodnik.value.pocetPsu,
    }
    const privateData = {
        ulice: zavodnik.value.ulice.trim(),
        mesto: zavodnik.value.mesto.trim(),
        stat: zavodnik.value.stat.trim(),
        predcisli: zavodnik.value.predcisli,
        email: zavodnik.value.email,
        filteremail: upravitText(zavodnik.value.email),
        zaplacenoCastka: zavodnik.value.zaplacenoCastka, 
        zaplacenoDate: Timestamp.fromDate(new Date(zaplacenoDateForm.value)),
        voditkoVelke: zavodnik.value.voditkoVelke,
        voditkoMale: zavodnik.value.voditkoMale,
        poznamka: zavodnik.value.poznamka,
        poznamkaPoradatele: zavodnik.value.poznamkaPoradatele,
        poznamkaVyrizenaDate: Timestamp.fromDate(new Date(poznamkaVyrizenaDateForm.value)),
        poznVyrizena: zavodnik.value.poznVyrizena,
        jmeno: zavodnik.value.jmeno.trim(),
        filterjmeno: upravitText(zavodnik.value.jmeno),
        prijmeni: zavodnik.value.prijmeni.trim(),
        filterprijmeni: upravitText(zavodnik.value.prijmeni),
        telefon: zavodnik.value.telefon,
        psi: zavodnik.value.psi.map(pes => ({
            jmenoPsa: pes.jmenoPsa,
            plemenoPsa: pes.plemenoPsa,
            datumPsa: pes.datumPsa ? Timestamp.fromDate(new Date(pes.datumPsa)) : null,
            cisloCipu: pes.cisloCipu,
            pracovka: pes.pracovka
        })),
        trasa: zavodnik.value.trasa,
        kategorie: zavodnik.value.kategorie,
        zaplaceno: zavodnik.value.zaplaceno,
        createdAt: originalValues.createdAt,
        startovne: startovne.value,
        pocetPsu: zavodnik.value.pocetPsu,
    }
    console.log('privateData: ', privateData)
    // když jiný telefon - celkové nové uložení závodníka a pak vymazání původního
    if (zavodnik.value.telefon !== originalValues.telefon) {
        console.log('proběhla změna telefonu')

        const zalozeni = await createNewRacer(publicData, privateData)
        if (zalozeni) {
            deleteDoc(docRefPrivate)
            deleteDoc(docRefPublic)

        } else {
            //tady by stálo za to spustit upozorňující modál ve smyslu - Uložení změn se nepovedlo, napiš Zdence, ať to opraví a kontakty na mě
            console.log('Chyba při vytváření nového závodníka')
            showChybu.value = true
        }
    } 
    else {
        try {
            await updateDoc(docRefPrivate, privateData)
            await updateDoc(docRefPublic, publicData)

        } catch (error) {
            console.log('Chyba při editaci závodníka', error)
            showChybu.value = true
        }
    }
    isPending.value = false
    router.push('/admin/main')
}



// když je něco špatně při ukládání private data, tak zůstanou uložená public data - to bude dělat bordel
const createNewRacer = async(publicData, privateData ) => {
    try {
        const publicCollectionRef = collection(db, 'zavodniciDtHolstejn', 'DT2024', 'public')
        await setDoc(doc(publicCollectionRef, zavodnik.value.telefon), publicData)
        const privateCollectionRef = collection(db, 'zavodniciDtHolstejn', 'DT2024', 'private')
        await setDoc(doc(privateCollectionRef, zavodnik.value.telefon), privateData)
        return true
    } catch (error) {
        console.error('Error during data submission:', error)
    }
}



//vymazání všech změn
const showDelete = ref(false)
const askDeleteChanges = () => {
    showDelete.value = true
}
const deleteChanges = () => {
    zavodnik.value = { ...props.zavodnik }
    noviPsi.value = []
    showDelete.value = false
}


// vymazání závodníka - jako jeho uložení v nové kolekci "deletedRacers" a pak smazání ze současných
const askDeleteRacer = ref(false)
const deleteRacer = async() => {
    console.log('deleteRacer')
    isPending.value = true
    askDeleteRacer.value = false

    // část s vytvořením mazaného závodníka v jiné kolekci
    const dataDelete = {
        ulice: zavodnik.value.ulice.trim(),
        mesto: zavodnik.value.mesto.trim(),
        stat: zavodnik.value.stat.trim(),
        predcisli: zavodnik.value.predcisli,
        email: zavodnik.value.email,
        filteremail: upravitText(zavodnik.value.email),
        zaplacenoCastka: zavodnik.value.zaplacenoCastka, 
        zaplacenoDate: Timestamp.fromDate(new Date(zaplacenoDateForm.value)),
        voditkoVelke: zavodnik.value.voditkoVelke,
        voditkoMale: zavodnik.value.voditkoMale,
        poznamka: zavodnik.value.poznamka,
        poznamkaPoradatele: zavodnik.value.poznamkaPoradatele,
        poznamkaVyrizenaDate: Timestamp.fromDate(new Date(poznamkaVyrizenaDateForm.value)),
        poznVyrizena: zavodnik.value.poznVyrizena,
        jmeno: zavodnik.value.jmeno.trim(),
        filterjmeno: upravitText(zavodnik.value.jmeno),
        prijmeni: zavodnik.value.prijmeni.trim(),
        filterprijmeni: upravitText(zavodnik.value.prijmeni),
        telefon: zavodnik.value.telefon,
        psi: zavodnik.value.psi.map(pes => ({
            jmenoPsa: pes.jmenoPsa,
            plemenoPsa: pes.plemenoPsa,
            datumPsa: pes.datumPsa ? Timestamp.fromDate(new Date(pes.datumPsa)) : null,
            cisloCipu: pes.cisloCipu,
            pracovka: pes.pracovka
        })),
        trasa: zavodnik.value.trasa,
        kategorie: zavodnik.value.kategorie,
        zaplaceno: zavodnik.value.zaplaceno,
        createdAt: zavodnik.value.createdAt,
        pocetPsu: zavodnik.value.pocetPsu,
        startovne: zavodnik.value.startovne,
        }
    try {
        const CollectionRef = collection(db, 'zavodniciDtHolstejn', 'DT2024', 'deletedRacers')
        await setDoc(doc(CollectionRef, zavodnik.value.telefon), dataDelete)
    } catch (error) {
        console.error('Error during data submission:', error)
    }
    // část s mazáním závodníka
    const docRefPublic = doc(db, 'zavodniciDtHolstejn', 'DT2024', 'public', originalValues.telefon)
    const docRefPrivate = doc(db, 'zavodniciDtHolstejn', 'DT2024', 'private', originalValues.telefon)
    deleteDoc(docRefPrivate)
    deleteDoc(docRefPublic)
    //ukončení fce
    isPending.value = false
    router.push('/admin/main')
}

//převod data 
const jsDate = new Date(zavodnik.value.createdAt.seconds * 1000 + zavodnik.value.createdAt.nanoseconds / 1e6)
const prijetiPrihlasky = format(new Date(jsDate), 'dd. MM. yyyy')


</script>

<style lang="scss" scoped>

.editClass {
    color: $warning; 
    background: $accent;
}

</style>
<template>
    <CeleMenu />

    <div class="row justify-around" style="height: 65vh; overflow: auto;">
        <div class="col-12 q-pa-xl">

            <h3>{{detail.title}}</h3>
            <div class="row justify-around">
                <div
                    v-for="(image, index) in Gallery.obrazky"
                    :key="index"
                    class="col-6 col-sm-4 col-md-2 q-pa-xs"
                >                    
                    <q-img
                        :src="image"
                        spinner-color="white"
                        style="height: 200px"
                        @click="useObr(index)"
                    />
                </div>
            </div>
            <div v-if="showModal">
                <Modal 
                    @toggleDotaz = "toggleDotaz"
                    theme = "photo"
                >
                    <!--
                    <q-img
                        :src="obr"
                        spinner-color="white"
                        fit="scale-down"
                    />
                    -->
                    <q-carousel
                        animated
                        v-model="obr"
                        infinite
                        arrows
                        transition-prev="slide-right"
                        transition-next="slide-left"
                        style="border-style: double; border-color: white; border-width: 1vh; width: 100%; height: 90vh"
                        class="shadow-5"
                        
                    >
                        <!--min-width:50vh; min-height: 50vh
                        <q-carousel-slide
                            v-for="(image, index) in Gallery.obrazky"
                            :key="image"
                            :img-src="Gallery.obrazky[index]"
                            :name="index"
                            
                        />-->
                        <q-carousel-slide
                            v-for="(image, index) in Gallery.obrazky"
                            :key="image"
                            :name="index"
                        >
                            <q-img :src="Gallery.obrazky[index]" style="object-fit: contain; width: 100%; height: 100%;" />
                        </q-carousel-slide> 
                    </q-carousel>
                </Modal>
            </div>

        </div>
    </div>

    <div class="col-12 self-end footer item" style="height: 5vh;">
        <FooterSection />
    </div>

</template>

<script setup>

// importy
    import CeleMenu from '@/components/CeleMenu.vue'
    import FooterSection from '@/components/FooterSection.vue'
    import Modal from '@/components/Modal.vue'
    import { useGallery } from '@/stores/useGallery'
    import { useRoute } from 'vue-router'
    import { ref, onMounted } from 'vue'

    import { storage } from "@/firebase/config.js"
    import {ref as firebaseRef } from 'firebase/storage'


// route
    const route = useRoute()

// zadání props
    const props = defineProps(['cesta'])

// store
  const Gallery = useGallery()

// získání konkrétní fotogalerie z Pinie přes adresu
    const detail = ref(null)
    detail.value = Gallery.getGalleryDetail(route.params.cesta)

// složka ve storage
    const imagesRef = firebaseRef(storage, detail.value.storage)

    onMounted(() => {
        // automatické získávání adres obrázků přes Pinii
        if (!Gallery.obrazky.length) {
            Gallery.listImages(imagesRef)
        }
    })

// zobrazení modálu
    const showModal = ref(false)
    const toggleDotaz = () => {
        showModal.value = !showModal.value
    }

    // výběr konkrétního obrázku
        const imageStyle = ref('')
        const obr = ref('')
        const useObr = (i) => {
            obr.value = i
            //obr.value = Gallery.obrazky[i]


            toggleDotaz()
        }




</script>

<style>

</style>
<template>
    <NavBar />

    <div class=" q-ma-xl">
        <h3>Editace závodníků</h3>
        <div v-if="zavodnik.jmeno">
            <EditForm 
                :zavodnik = "zavodnik"
            />
        </div>
        <div v-else>
            <div class="q-pa-md">
                <q-linear-progress query />
                <q-linear-progress query color="primary" class="q-mt-sm" />
                <q-linear-progress query color="secondary" class="q-mt-sm" />
                <q-linear-progress query color="accent" class="q-mt-sm" />
                <q-linear-progress query color="primary" class="q-mt-sm" />
                <q-linear-progress query color="secondary" class="q-mt-sm" />
                <q-linear-progress query color="accent" class="q-mt-sm" />
            </div>
        </div>
    </div>
</template>

<script>
import queryForIds from '@/composables/queryForIds'

import EditForm from '@/components/admin/EditForm.vue'
import NavBar from '@/components/admin/NavBar.vue'


import { onMounted, ref } from 'vue'


export default {
    components: { NavBar, EditForm  },
    props: ['id'],


    setup(props) {
        const zavodnik = ref([])
        // const publicData = ref([])
        // const privateData = ref([])
        const privateAdress = ['zavodniciDtHolstejn', 'DT2024', 'private']
        // const publicAdress = ['zavodniciDtHolstejn', 'DT2024', 'public']
        const id = props.id
        onMounted(() => {
            const proved = async () => {
                //const publiczav = await queryForIds(publicAdress, id)
                const privatezav = await queryForIds(privateAdress, id)
                //publicData.value = publiczav[0].data
                //privateData.value = privatezav[0].data
                //zavodnik.value = { ...publicData.value, ...privateData.value }
                zavodnik.value = privatezav[0].data
            }
            proved()
        })
        return {
            zavodnik,
        }
    }
}

</script>

<style>

</style>
import { ref } from 'vue'
import { db } from '../firebase/config'
import { collection, query, where, getCountFromServer } from 'firebase/firestore'


const getCount = async(c, q, q2, q3) => {
    const count = ref(null)
    let colRef = collection(db, ...c)
    if (q && q2 && q3) {
        colRef = query(colRef, where(...q), where(...q2), where(...q3))
    }
    
    else if (q && q2) {
        colRef = query(colRef, where(...q), where(...q2))
    }

    else if (q) {
        colRef = query(colRef, where(...q))
    }
    try {
      const snapshot = await getCountFromServer(colRef)
      const countData = snapshot.data()
      if (countData) {
        count.value = countData.count
      } else {
        console.error('Count data not available.')
      }
    } catch (error) {
      console.error('Error getting count:', error.message)
    }    
    
    return { count }
}

export default getCount
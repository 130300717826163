<template>
  <NavBar />
  <div class=" q-ma-xl">
    <AddNew />
    <div class="q-mt-xl">
      <q-btn v-if="!newsTable" @click="newsTable=true" color= "accent" padding="lg" text-color="secondary">Zobrazit výpis novinek</q-btn>
      <q-btn v-if="newsTable" @click="newsTable=false" color= "accent" padding="lg" text-color="secondary">Skrýt výpis novinek</q-btn>
      <div v-if="newsTable">
        <ShowNews />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/admin/NavBar.vue'
import AddNew from '@/components/admin/AddNew.vue'
import ShowNews from '@/components/admin/ShowNews.vue'
import { ref } from 'vue'



export default {
  components: { NavBar, AddNew, ShowNews },

  setup() {

    const newsTable = ref(false)










    return {
      newsTable
    }

  }





}
</script>

<style>

</style>
<template>
    <div class="full-width row wrap justify-center items-center content-center" style="height: 100vh; align: center;">
        <div 
            class="col-12"
            style="height: 100%; width: 100%; background-color: white; opacity: 65%; z-index: 100; position: fixed; top: 0; left: 0;"
            @click="toggleDotaz">    
            <div style="position: absolute; top: 10px; right: 10px; z-index: 800">
                <q-btn round outline color="dark" icon="close" padding="none" size="lg" @click="toggleDotaz" />
            </div>  
        </div>                                
        <div class="col-xs-12 col-sm-11 items-center content-stretch justify-center"  
            :class="{
                photo: theme === 'photo',
              
            }" 
            style="z-index:300; align:center">
            
            <slot>
                
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['theme'],
    emits: ['toggleDotaz', 'handleSubmit'],

    setup(props ,emits) {
        
        const toggleDotaz = (() =>{
            emits.emit('toggleDotaz')
        })

        const handleSubmit = (() =>{
            emits.emit('handleSubmit')
        })



        return {toggleDotaz, handleSubmit}
    }
}

</script>

<style>

</style>
<template>
    <q-expansion-item>
                <template v-slot:header >
                    <q-item-section >
                        <h4 >{{ listina.nadpis }}</h4>
                    </q-item-section>
                </template>
                <q-card >
            <q-card-section>
                    <start-list-table 
                        :listina="listina"/>
            </q-card-section>
        </q-card>
            </q-expansion-item>


</template>

<script>

import StartListTable from './StartListTable.vue'

export default {
  components: { StartListTable },
    props: ['listina'],


    setup(props){

        return {

        }

    }

}
</script>

<style>

</style>
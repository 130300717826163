// funkce hledající podle id předaných z předchozích výsledků a vyhledávající podle nich v druhé podkolekci


import { ref } from 'vue'

import { db } from '../firebase/config'
import {  doc, getDoc } from 'firebase/firestore'


const queryForIds = async (a, id) => {
    console.log('queryForIds')
    let docRef = doc(db, ...a, id)
    const queryResults = ref([])
    const docSnapshot = await getDoc(docRef)
    console.log('docSnapshot: ',  docSnapshot)

    if (!docSnapshot.empty) {
        queryResults.value.push({
            id: docSnapshot.id,
            data: docSnapshot.data()
        })
    }
    return queryResults.value
}

export default queryForIds
<template>
    <div>
        <b>Při hledání používejte diakritiku a u jmen první velká písmena</b>
    </div>
    <form  @submit.prevent="handleSubmit" 
        class="q-gutter-sm"
        shouldFocus: true
        >
        <q-input 
            outlined
            type="text"
            label="jméno"
            v-model="jmeno" 
        />
        <q-input 
            outlined
            type="text"
            label="příjmení"
            v-model="prijmeni" 
        />
        <span>Telefonní číslo je pro každého závodníka unikátní, pokud ho znáte, vrátí nejpřesnější výsledek (a nic jiného není třeba zadávat)</span>
        <q-input 
            outlined
            type="text"
            label="Telefon"
            v-model="telefon" 
            mask="### ### ###"
        />
        <q-input 
            outlined
            type="text"
            label="E-mail"
            v-model="email" 
        />
        <q-btn  v-if="!isPending" type="submit" style="background: #726d65; color: white">Hledat</q-btn>
        <q-btn  v-if="isPending" disable type="submit" style="background: #726d65; color: white">Hledám</q-btn>
        <q-btn  style="background: #726d65; color: white" @click="nullForm">Vynulovat formulář</q-btn>


    </form>
    <div class="q-mt-lg" v-if="nenalezen">
        <h3>žádného takového závodníka se nepovedlo najít</h3>
        Zkontroluj diakritiku a velikost písmen
    </div>
    <div class="q-mt-lg" v-if="zavodnik.length">
        <RacersTab 
            :isPending="isPending"
            :zavodnik="zavodnik"
        />
    </div>




</template>

<script setup>

import RacersTab from '@/components/admin/RacersTab.vue'
import { ref, watchEffect } from 'vue'

import { db } from '@/firebase/config'
import { collection, query, where, onSnapshot, } from "firebase/firestore"

//proměnné formuláře
    const jmeno = ref(null)
    const prijmeni = ref(null)
    const telefon = ref(null)
    const email = ref(null)

//pro zobrazení hlášky o nenalezení závodníka
    const nenalezen = ref(false)
//pro blokaci tlačítka během vyhledávání
    const isPending = ref(false)
    const zavodnik = ref([])

//úprava textu pro vyhledávání
    const upravitText = (text) => {
        let upravenyText = text.trim();
        upravenyText = upravenyText.toLowerCase();
        upravenyText = upravenyText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return upravenyText;
    }


//fce odeslání formuláře
    const handleSubmit = async() => {

        zavodnik.value = []
        //při hledání více lidí po sobě
        nenalezen.value = false
        isPending.value = true

        // hledání nově jen v private kolekci, kde jsou všechna data
        const adress = ref(['zavodniciDtHolstejn', 'DT2024', 'private'])
        
        const queryPrivate  = collection(db, 'zavodniciDtHolstejn', 'DT2024', 'private')
        let dynamicQuery = queryPrivate
        //telefon je unikátní hodnota - můžu vlastně volat obě subkolekce a zadat ho rovnou jako ID, stejně potřebuju data z obou subkolekcí
        if (telefon.value) {
            dynamicQuery = query(dynamicQuery, where('telefon', '==', telefon.value))
        /*    
        } if ((jmeno.value || prijmeni.value) && !email.value) {
            if (jmeno.value) {
                dynamicQuery = query(dynamicQuery, where('filterjmeno', '==',  upravitText(jmeno.value)))
            }
            if (prijmeni.value) {
                dynamicQuery = query(dynamicQuery, where('filterprijmeni', '==',  upravitText(prijmeni.value)))
            }
        } if ( !jmeno.value && !prijmeni.value && email.value ){
            dynamicQuery = query(queryPrivate, where('filteremail', '==', upravitText(email.value)))
            // adress.value = publicAdress
        }    
            else if (email.value && (jmeno.value || prijmeni.value)) {
            dynamicQuery = query(queryPrivate, where('filteremail', '==', upravitText(email.value)))
            adress.value = publicAdress
            if (jmeno.value) {
                q.push(['filterjmeno', '==', upravitText(jmeno.value)])
            }
            if (prijmeni.value) {
                q.push(['filterprijmeni', '==', upravitText(prijmeni.value)])
            }
        }
        */
        } else {
            if ( jmeno.value ) {
                dynamicQuery = query(dynamicQuery, where('filterjmeno', '==',  upravitText(jmeno.value)))
            } if ( prijmeni.value ) {
                dynamicQuery = query(dynamicQuery, where('filterprijmeni', '==',  upravitText(prijmeni.value)))
            } if ( email.value ){
                dynamicQuery = query(queryPrivate, where('filteremail', '==', upravitText(email.value)))
            }
        }

        const unsub = await onSnapshot(dynamicQuery, snapshot => {
            let results = []
            snapshot.docs.forEach(doc => {
            results.push({ ...doc.data(), id: doc.id })
            })
            
            // update values
            zavodnik.value = results
                if (!zavodnik.value.length) {
                    nenalezen.value = true
                }
            })

        watchEffect((onInvalidate) => {
            onInvalidate(() => unsub());
        })

        isPending.value = false
    }


//nulování formuláře - ovládané přes tlačítko
    const nullForm = () => {
        jmeno.value = null
        prijmeni.value = null
        telefon.value = null
        email.value = null
    }

</script>

<style>

</style>
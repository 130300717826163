<template>
<div class="absolute-bottom custom-caption xs">
    <div class="text-h3">Dogtrekking za pokladem Voka IV. z Holštejna</div>
</div>
<div class="absolute-bottom custom-caption gt-xs">
    <div class="text-h2">Dogtrekking za pokladem Voka IV. z Holštejna</div>
</div>


</template>

<script>
export default {

}
</script>

<style>

</style>
<template>
   <CeleMenu />

<!--tahle část je vlastní obsah routy-->
    <div class="row justify-around" style="height: 65vh; overflow: auto;">
        <div class="col-12 q-pa-xl">
            <h4>Propozice společné pro všechny kategorie</h4> 
            <h5>Termín celé akce</h5>
                <p>{{ termin }}</p>
            <h5>Místo</h5>
                <p>Holštejn - Hostinec pod Hradem Holštejn </p>
            <h5>Podmínky účasti</h5>
                <p>Každý účastník ručí za škody způsobené svým psem. Platné očkování psa proti vzteklině, pes musí být zdravý! Start bude povolen pouze za dodržení všech ustanovení zákona. č.246/1992 Sb. (zákon na ochranu zvířat proti týrání) a č.77/2004 Sb. v platném znění. Závodu se nesmí účastnit psi s kupírovanýma ušima. Zahraniční účastníci předloží platný pas zvířete v zájmovém chovu.</p>
            <h5>Veterinární přejímka</h5>
                <p>během prezence. Budou kontrolovány očkovací průkazy i těch psů, kteří se závodu neúčastní.</p>
            <h5>Mapa</h5>
                <p>Potřebný výřez obdrží každý závodník při prezenci.</p>
            <h5>Ubytování</h5>
                <p>- ve vlastních stanech nebo pod širákem (netřeba objednávat předem)
                <br>- individuálně v ubytovacích zařízeních v blízkém či vzdálenějším okolí – například kemp Baldovec</p>
            <h5>Další informace</h5>
                <p>Pes musí být po celou dobu závodu na vodítku, volný pohyb psa není možný!
                <br>Závodníci jsou během závodu odkázáni sami na sebe, do závodu vstupují na vlastní nebezpečí.
                Závodník, který odstoupí z trasy, je o tom povinen informovat pořadatele i v případě vlastního odvozu! Na nouzovém telefonu či osobně v místě startu</p>    
            <h5>Odvoz z trati</h5>
                <p>Bude probíhat pouze od 8:00 - 20:00 - mimo tuto dobu svozy nebudou, z toho důvodu doporučujeme nepodceňovat povinnou výbavu
                <br>ZDARMA při zranění nebo vážných zdravotních potížích psovoda či jeho psa, za POPLATEK 100-300 Kč na požádání závodníka (dle vzdálenosti)
                <br>Závodník mladší 15 let se může akce zúčastnit pouze v doprovodu dospělé osoby
                <br>Závodníci ve věku 15 - 18 let předloží u prezence písemný souhlas rodičů
                <br>Případné protesty, návrhy diskvalifikace účastníků atp. je nutné nahlásit pořadateli nejpozději do neděle 10:00</p>
            
                <q-expansion-item>
                    <template v-slot:header>
                        <q-item-section>
                            <h4>Propozice specifické pro long</h4>
                        </q-item-section>
                    </template>
                    <q-card>
                        <q-card-section>
                            <h5>Délka trasy, časový limit</h5>
                                <p>100-105 km (uzavření cíle: neděle {{ nedele }} 10:00)</p>
                            <h5>Startovné</h5>
                                <p>- 1. uzávěrka {{ uzaverka }} - 500 Kč
                                <br>- 2. uzávěrka {{ uzaverka2 }} - 600 Kč
                                <br><b>Prosím pozor
                                <br>Startovné nelze po 2.uzávěrce převádět na jiného závodníka.
                                <br>Startovné je NEVRATNÉ</b>
                                <br>Po první uzávěrce nelze zadat upomínkové předměty
                                <br>Zahraniční závodníci platí až na místě.</p>
                            <h5>Kategorie</h5>
                                <p>DTM 1: muži do 40-ti let včetně
                                <br>DTW 1: ženy do 35-ti let včetně
                                <br>DTM 2: muži nad 40 let
                                <br>DTW 2: ženy nad 35 let
                                <br>PLYŠÁK: všichni závodníci bez pejska</p>
                            <h5>Věk psa</h5>
                                <p>Minimální věk psa je 18 měsíců v den startu</p>
                            <h5>Prezence</h5>
                                <p> {{ ctvrtek }} 19:00 - 22:00 hod
                                <br>Prezentace mimo časové rozpětí ve čtvrtek pouze po dohodě, v {{ patek }} AŽ po odstartování všech týmů.
                                <br>Závodníci obdrží mapu a startovací kartu.</p>
                            <h5>Briefing</h5>
                                <p>{{ briefingLong }} <b>nepovinný, ale doporučujeme!</b>
                                <br>Účastníci se při briefingu dozví podrobnosti o trati, bivaku, kontrolních bodech, nouzové tel. číslo apod.</p>
                            <h5>Start</h5>
                                <p>{{patek}} 07:00 - 09:00 hod
                                <br>Závodníci v tomto časovém okně startují v libovolném pořadí v časovém intervalu nejméně 1 minuty, čas startu jim bude vyznačen na startovní kartu. Pro dogtrekking je případně možné startovat kdykoli v pátek či v sobotu, jako čas startu však bude vyznačeno - pátek v 9:00 hod, závodník si tak zkrátí standardní časový limit (do cíle je třeba dorazit nejpozději v neděli v 10:00 hod.). Pokud se tak rozhodnete, uveďte to prosím v přihlášce do poznámky.</p>
                            <h5>Kontrolní body</h5>
                                <p>Podobu kontrolních bodů ještě upřesníme. Alespoň na jednom kontrolním bodě bude „živá kontrola“, v tomto místě bude možno doplnit zásoby vody. Náhodné kontroly se zaměří zejména na dodržování zákazu volného pohybu psů v závodě a na kontrolu povinné výbavy.</p>
                            <h5>Povinná výbava</h5>
                                <p>Může být kontrolována na startu, kdekoli na trati i po příchodu do cíle!
                                <br>- čelová svítilna nebo baterka 
                                <br>- strava a láhev s vodou pro každého psa (voda v odlišné nádobě než voda pro dvounožce), se kterým účastník startuje
                                <br>- miska pro psa
                                <br>- mapa, startovací karta (obdrží účastník při prezenci)
                                <br>- lékárnička (1x obvaz hotový, 1x obinadlo elastické, 5x náplast s polštářkem, 1x izotermická fólie)
                                <br>- dvě botičky pro každého psa s kterým vyrazí účastník na trať</p>
                            <h5>Doporučená výbava</h5>
                                <p>- bivakovací potřeby: spací pytel, karimatka (alumatka nebo hamaka)
                                <br>- strava a pití pro člověka
                                <br>- Nabitý mobilní telefon, náhradní baterie do čelovky, psí nosič, buzola...
                                <br>- Zahraničním účastníkům doporučujeme uzavřít pojištění léčebných náhrad!</p>
                            <h5>Vyhlášení výsledků</h5>
                                <p>neděle {{ nedele }} 10:30 hod.
                                <br><b>Ceny si budou účastníci přebírat výhradně osobně v době určené pořadatelem.</b> Kdo se vyhlášení nezúčastní, ztrácí nárok na ceny. Vyzvednout předem či zaslat poštou lze pouze pamětní listy.</p>
                        </q-card-section>
                    </q-card>
                </q-expansion-item>

                <q-expansion-item>
                    <template v-slot:header>
                        <q-item-section>
                            <h4>Propozice specifické pro mid</h4>
                        </q-item-section>
                    </template>
                    <q-card>
                        <q-card-section>
                            <h5>Délka trasy, časový limit</h5>
                                <p>cca 40 km (uzavření cíle: neděle {{ nedele }} 10:00)</p>
                            <h5>Startovné</h5>
                                <p>- 1. uzávěrka {{ uzaverka }} - 400 Kč
                                <br>- 2. uzávěrka {{ uzaverka2 }} - 500 Kč
                                <br><b>Prosím pozor
                                <br>Startovné nelze po 2.uzávěrce převádět na jiného závodníka.
                                <br>Startovné je NEVRATNÉ</b>
                                <br>Po první uzávěrce nelze zadat upomínkové předměty
                                <br>Zahraniční závodníci platí až na místě.</p>
                            <h5>Kategorie</h5>
                                <p>DMM 1: muži do 40-ti let včetně
                                <br>DMW 1: ženy do 35-ti let včetně
                                <br>DMM 2: muži nad 40 let
                                <br>DMW 2: ženy nad 35 let
                                <br>PLYŠÁK: všichni závodníci bez pejska</p>
                            <h5>Věk psa</h5>
                                <p>Minimální věk psa je 18 měsíců v den startu</p>
                            <h5>Prezence</h5>
                                <p> {{ patek }} 19:00 - 22:00 hod
                                <br>Prezentace mimo časové rozpětí v pátek pouze po dohodě, v sobotu {{sobota}} AŽ po odstartování všech týmů.
                                <br>Závodníci obdrží mapu a startovací kartu.</p>
                            <h5>Briefing</h5>
                                <p>{{ briefingMid }} <b>nepovinný, ale doporučujeme!</b>
                                <br>Účastníci se při briefingu dozví podrobnosti o trati, kontrolních bodech, nouzové tel. číslo apod.</p>
                            <h5>Start</h5>
                                <p>sobota {{sobota}} 07:00 - 09:00 hod
                                <br>Závodníci v tomto časovém okně startují v libovolném pořadí v časovém intervalu nejméně 1 minuty, čas startu jim bude vyznačen na startovní kartu. Pro dogtrekking je případně možné startovat kdykoli v pátek či v sobotu, jako čas startu však bude vyznačeno - pátek v 9:00 hod, závodník si tak zkrátí standardní časový limit (do cíle je třeba dorazit nejpozději v neděli v 10:00 hod.). Pokud se tak rozhodnete, uveďte to prosím v přihlášce do poznámky.</p>
                            <h5>Kontrolní body</h5>
                                <p>Podobu kontrolních bodů ještě upřesníme. Alespoň na jednom kontrolním bodě bude „živá kontrola“, v tomto místě bude možno doplnit zásoby vody. Náhodné kontroly se zaměří zejména na dodržování zákazu volného pohybu psů v závodě a na kontrolu povinné výbavy.</p>
                            <h5>Povinná výbava</h5>
                                <p>Může být kontrolována na startu, kdekoli na trati i po příchodu do cíle!
                                <br>- čelová svítilna nebo baterka 
                                <br>- strava a láhev s vodou pro každého psa (voda v odlišné nádobě než voda pro dvounožce), se kterým účastník startuje
                                <br>- miska pro psa
                                <br>- mapa, startovací karta (obdrží účastník při prezenci)
                                <br>- lékárnička (1x obvaz hotový, 1x obinadlo elastické, 5x náplast s polštářkem, 1x izotermická fólie)
                                <br>- dvě botičky pro každého psa s kterým vyrazí účastník na trať</p>
                            <h5>Doporučená výbava</h5>
                                <p>- strava a pití pro člověka (pití v odlišné nádobě než voda pro psa)
                                <br>- nabitý mobilní telefon, náhradní baterie do čelovky, psí nosič, buzola...
                                <br>- Zahraničním účastníkům doporučujeme uzavřít pojištění léčebných náhrad!</p>
                            <h5>Vyhlášení výsledků</h5>
                                <p>neděle {{ nedele }} 10:30 hod.
                                <br><b>Ceny si budou účastníci přebírat výhradně osobně v době určené pořadatelem.</b> Kdo se vyhlášení nezúčastní, ztrácí nárok na ceny. Vyzvednout předem či zaslat poštou lze pouze pamětní listy.</p>
                        </q-card-section>
                    </q-card>
                </q-expansion-item>


                <q-expansion-item>
                    <template v-slot:header>
                        <q-item-section>
                            <h4>Propozice specifické pro vycházku (short)</h4>
                        </q-item-section>
                    </template>
                    <q-card>
                        <q-card-section>
                            <h5>Délka trasy, časový limit</h5>
                                <p>cca 20 km (uzavření cíle: neděle {{ nedele }} 10:00)</p>
                            <h5>Startovné</h5>
                                <p>- 1. uzávěrka {{ uzaverka }} - 250 Kč
                                <br>- 2. uzávěrka {{ uzaverka2 }} - 350 Kč
                                <br><b>Prosím pozor
                                <br>Startovné nelze po 2.uzávěrce převádět na jiného závodníka.
                                <br>Startovné je NEVRATNÉ</b>
                                <br>Po první uzávěrce nelze zadat upomínkové předměty
                                <br>Zahraniční závodníci platí až na místě.</p>
                            <h5>Kategorie</h5>
                                <p>bez kategorií</p>
                            <h5>Věk psa</h5>
                                <p>Minimální věk psa je 12 měsíců v den startu</p>
                            <h5>Prezence</h5>
                                <p> sobota {{ sobota }} 8:30 hod</p>
                            <h5>Start</h5>
                                <p>sobota {{ sobota }} 8:30 hod a později</p>
                            <h5>Kontrolní body</h5>
                                <p>Na trati bude několik kontrolních bodů s kleštičkami, jimiž si závodník do startovací karty označí návštěvu kontrolního bodu.</p>
                            <h5>Povinná výbava</h5>
                                <p>Očkovací průkaz</p>
                            <h5>Doporučená výbava</h5>
                                <p>svačina a v případě extrémně teplého počasí voda pro psa (na trase by měl být dostatek přírodních zdrojů vody)</p>
                            <h5>Vyhlášení výsledků</h5>
                                <p>tato kategorie je bez vyhlašování, každý účastník obdrží pamětní list a nějakou tu drobnost na památku</p>
                        </q-card-section>
                    </q-card>
                </q-expansion-item>
        
        
        </div>
    </div>
  <div class="col-12 self-end footer item" style="height: 5vh;">
    <FooterSection />
  </div>


</template>

<script>

import CeleMenu from '@/components/CeleMenu.vue'
import FooterSection from '@/components/FooterSection.vue'

import { useMeta } from 'vue-meta'



export default {
components: {CeleMenu, FooterSection},

setup() {

    useMeta({ title: 'Dogtrekking za pokladem Voka IV. z Holštejna - propozice' })

    const termin = '3. - 6. 10. 2024'
    const ctvrtek = 'čtvrtek 3. 10. 2024'
    const patek = 'pátek 4. 10. 2024'
    const sobota = '5. 10. 2024'
    const nedele = '6. 10. 2024'
    const uzaverka = '15. 8. 2024'
    const uzaverka2 = '15. 9. 2024'
    const briefingLong = 'čtvrtek  3. 10. 20:00'
    const briefingMid = 'pátek 4. 10. 20:00'


    return {
        termin,
        ctvrtek,
        patek,
        sobota,
        nedele,
        uzaverka,
        uzaverka2,
        briefingLong,
        briefingMid
    }
}



}
</script>

<style>

</style>
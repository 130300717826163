<template>
    <NavBar />
    <div class=" q-ma-xl">
        <div  v-if="zavodnik.jmeno" style="font-size: medium;">
            <h4>{{ zavodnik.jmeno }} {{ zavodnik.prijmeni }}</h4>
            <div>
                <p>přihláška přijata: {{ timestampToString(zavodnik.createdAt) }}</p>
            </div>
            <div>
                <p>
                    <b>Kontaktní údaje:</b><br>
                    telefon: {{ zavodnik.predcisli }} {{ zavodnik.telefon }} <br>
                    email: {{ zavodnik.email }} <br>
                    adresa: {{ zavodnik.ulice }}, {{ zavodnik.mesto }}, {{ zavodnik.stat }}
                </p>
            </div>
            <div>
                <p>
                    <b>Závod:</b><br>
                    trasa: {{ zavodnik.trasa }}<br>
                    kategorie: {{ zavodnik.kategorie }} <br>
                </p>
            </div>
            <div>
                <p><b>Psi:</b></p>
                <div v-if="zavodnik.psi.length">
                    <div v-for="(pes, index) in zavodnik.psi" :key="index">
                        <p>
                            jméno psa: {{ pes.jmenoPsa }}<br>
                            plemeno psa: {{ pes.plemenoPsa }}<br>
                            Datum narození psa: {{ timestampToString(pes.datumPsa) }}<br>
                            Číslo čipu psa: {{ pes.cisloCipu }}<br>
                            Pracovní třída: {{ pes.pracovka }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <p>Závodník nemá přihlášené žádné psy</p>
                </div>
            </div>
            <div>
                <p>
                    <b>Upomínkové předměty:</b><br>
                    vodítko malé: {{ zavodnik.voditkoMale }} ks<br>
                    vodítko velké: {{ zavodnik.voditkoVelke }} ks<br>
                </p>
            </div>
            <div>
                <p>
                    <b>Poznámka a její vyřešení:</b><br>
                    poznámka závodníka: {{ zavodnik.poznamka }}<br>
                    poznámka vyřešena kdy: {{ timestampToString(zavodnik.poznamkaVyrizenaDate) }}<br>
                    poznámka pořadatele: {{ zavodnik.poznamkaPoradatele }}
                </p>
            </div>
            <div>
                <p>
                    <b>Platba:</b><br>
                    placeno: {{ zavodnik.zaplaceno }}<br>
                    částka: {{ zavodnik.zaplacenoCastka }}<br>
                    placeno kdy: {{ timestampToString(zavodnik.zaplacenoDate) }}
                </p>
            </div>


        </div>


        <div v-else>
            <div class="q-pa-md">
                <q-linear-progress query />
                <q-linear-progress query color="primary" class="q-mt-sm" />
                <q-linear-progress query color="secondary" class="q-mt-sm" />
                <q-linear-progress query color="accent" class="q-mt-sm" />
                <q-linear-progress query color="primary" class="q-mt-sm" />
                <q-linear-progress query color="secondary" class="q-mt-sm" />
                <q-linear-progress query color="accent" class="q-mt-sm" />
            </div>
        </div>
        <div class="q-mt-sm">
            <router-link :to="{name: 'admineditace', params: {id: zavodnik.telefon}}">
                <q-btn label="Upravit údaje závodníka" style="background: #726d65; color: white" />
            </router-link>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/admin/NavBar.vue'

import queryForIds from '@/composables/queryForIds'

import { ref, onMounted } from 'vue'
import { format } from 'date-fns'

import { db } from '@/firebase/config'
import {  doc, getDoc } from 'firebase/firestore'



export default {
    components: {NavBar },
    props: ['id'],


    setup(props) {
        const zavodnik = ref([])
        const privateAdress = ['zavodniciDtHolstejn', 'DT2024', 'private']
        const publicAdress = ['zavodniciDtHolstejn', 'DT2024', 'public']
    
        const id = props.id
        const proved = async() => {
            const privateRef = doc(db, ...privateAdress, id)
            const publicRef = doc(db, ...publicAdress, id) 

            const publiczav = await getDoc(publicRef)

                if (!publiczav.empty) {
                    const publicData = publiczav.data()
                    const privatezav = await getDoc(privateRef)
                    if (!privatezav.empty) {
                        const privateData = privatezav.data()
                        zavodnik.value = { ...privateData, ...publicData }
                    }
                }

        }
        onMounted(() => {
            proved()
        })

        //převod timestampu na datum
        const timestampToString = (timestamp) => {
            if (timestamp === null) {
                return ""
            }
            const pesDate = (timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6)
            return format(new Date(pesDate), 'dd. MM. yyyy')
        }



        return {
            zavodnik,
            timestampToString
        }
    }


}
</script>

<style>

</style>
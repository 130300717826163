<template>
    <NavBar />
    <div class=" q-ma-xl">
      <form>
        <q-select 
          outlined 
          v-model="vyhledavani" 
          :options="vyhl" 
          label="Podle čeho chcete závodníky vyhledat a zobrazit" 
      />
      </form>
      <q-btn @click="toggleShowAll" style="background: #726d65; color: white" class="q-mt-lg">Zobrazit všechny závodníky</q-btn>  <br>    

      <!--podmíněné zobrazení komponent podle typu hledání-->
      <div v-if="hledaniCloveka" class="q-mt-lg">
        <FindHuman />
      </div>
      <div v-if="hledaniSkupiny" class="q-mt-lg">
        <FindCategory />
      </div>
      <div v-if="showAll" class="q-mt-lg">
        <showAllR />
      </div>



    </div>
</template>

<script setup>
// komponenty
  import NavBar from '@/components/admin/NavBar.vue'
  import FindHuman from '@/components/admin/FindHuman.vue'
  import FindCategory from '@/components/admin/FindCategory.vue'
  import showAllR from '@/components/admin/showAll.vue'
  import showWorkClass from '@/components/admin/showWorkClass.vue'

// další importy
  import { ref, watch } from 'vue'



//možnosti pro základní rozdělovník
  const vyhl = ['chci všechny závodníky přihlášené na danou trasu nebo v dané kategorii', 'chci najít konkrétního člověka']
  const vyhledavani = ref('')

//přepínač vyhledávání mezi dokumentem a kolekcí - zobrazuje jiná vyhledávací pole
  const hledaniSkupiny = ref(false)
  const hledaniCloveka = ref(false)
  const showAll = ref(false)


  watch(vyhledavani, (newValue) => {
    if (newValue === 'chci všechny závodníky přihlášené na danou trasu nebo v dané kategorii') {
      hledaniSkupiny.value = true
      hledaniCloveka.value = false
      showAll.value = false

    }
    else if (newValue === 'chci najít konkrétního člověka') {
      hledaniSkupiny.value = false
      hledaniCloveka.value = true
      showAll.value = false

    }
  })

  const toggleShowAll = () => {
    showAll.value = true
    hledaniSkupiny.value = false
    hledaniCloveka.value = false
    vyhledavani.value = ''
  }
  const toggleShowWork = () => {
    showAll.value = false
    hledaniSkupiny.value = false
    hledaniCloveka.value = false
    vyhledavani.value = ''
  }

</script>

<style>

</style>
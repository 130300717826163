<template>
    <div v-if="startovka">
        <q-table
            :rows="startovka"
            :columns="columns"
            row-key="id"
            sortBy= "prijmeni"
            :rows-per-page= "startovka.length"
            :rows-per-page-label="'Řádků na stránku'"
            :rows-per-page-options="[startovka.length, 10, 20, 50]"
            class="startovka"
            no-data-label="Máš šanci být první přihlášený na této trati!"
            >

            <template v-slot:header="props">
                <q-tr :props="props">
                    <q-th auto-width />
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                        {{ col.label }}
                    </q-th>
                </q-tr>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn v-if="props.row.psi.length > 0" size="sm" color="accent" round dense @click="props.expand = !props.expand" :icon="props.expand ? 'remove' : 'add'" />
                    </q-td>
                    <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        <q-icon v-if="col.name === 'zaplaceno'" :name="props.row.zaplaceno ? 'check' : 'close'" :color="props.row.zaplaceno ? 'positive' : 'negative'" />
                        <span v-else>{{ col.value }}</span>                    
                    </q-td>
                </q-tr>
                <q-tr v-show="props.expand" :props="props">
                    <q-td colspan="100%">
                        <q-table 
                            :rows="props.row.psi" 
                            :columns="psiColumns" 
                            row-key="jmenoPsa" 
                            :rows-per-page="props.row.psi.length" 
                            hide-bottom
                            class="startovkapsi"
                        />
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
    <div v-else>
        <div class="q-pa-md">
            <q-linear-progress query />
            <q-linear-progress query color="primary" class="q-mt-sm" />
            <q-linear-progress query color="secondary" class="q-mt-sm" />
            <q-linear-progress query color="accent" class="q-mt-sm" />
            <q-linear-progress query color="primary" class="q-mt-sm" />
            <q-linear-progress query color="secondary" class="q-mt-sm" />
            <q-linear-progress query color="accent" class="q-mt-sm" />
        </div>
    </div>

</template>

<script>
import getSubcollection from '@/composables/getSubcollection'

export default {
    props: ['listina'],


    setup(props){

        const { documents: startovka } = getSubcollection(
        ['zavodniciDtHolstejn', 'DT2024', 'public'],
        [ 'trasa', '==', props.listina.trasa ],
        'prijmeni'
        )

        const columns = [
        //{ name: 'id', label: 'Id', field: row => row.id, sortable: true },
    
        {
                name: 'prijmeni',
                required: true,
                label: 'Příjmení',
                align: 'left',
                field: row => row.prijmeni,
                sortable: true
            },
            { name: 'jmeno', label: 'Jméno', field: row => row.jmeno, sortable: true },
            { name: 'kategorie', label: 'Kategorie', field: row => row.kategorie, sortable: true },

            { name: 'psi', label: 'Počet psů', field: row => row.psi.length, sortable: true },
            { name: 'zaplaceno', label: 'Zaplaceno', field: row => row.zaplaceno, sortable: true },

            ]

        const togglePsiRow = (row) => {
            row.expand = !row.expand;
        }

        const psiColumns = [
            { name: 'jmenoPsa', label: 'Jméno psa', field: (row) => row.jmenoPsa, sortable: true, align: 'left' },
            { name: 'plemenoPsa', label: 'Plemeno', field: (row) => row.plemenoPsa, sortable: true, align: 'left' },

        ]


        console.log(startovka)
        console.log(startovka.value)



        return {
            columns,
            togglePsiRow,
            psiColumns,
            startovka
        }

    }

}
</script>

<style>

</style>
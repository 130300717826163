<template>
  <metainfo>
    
    <template v-slot:title="{ content }">{{ content ? `${content}` : `Dogtrekking za pokladem Voka IV. z Holštejna` }}</template>
  </metainfo>
  <div class="row content-stretch" style="height: 100vh;">
    <div class="col-12 col-md-10 col-xl-8 telo shadow-12" >
      <router-view />
    </div>
  </div>
</template>

<script>
  import { useMeta } from 'vue-meta'

export default {
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })


  }
}
</script>

<template>
    <NavBar />
    <div class=" q-ma-xl row">
        <div class="col-12 col-md-10 col-lg-7">
            <h3>Editace novinek</h3>
        </div>
        <div v-if="novinka.nadpis" class="col-12 col-md-10 col-lg-7">
            <EditNew 
                :novinka = "novinka"
                :id = "id"
            />
        </div>
    </div>
</template>

<script>

import NavBar from '@/components/admin/NavBar.vue'
import EditNew from '@/components/admin/EditNew.vue'
import queryForIds from '@/composables/queryForIds'

import { ref, onMounted } from 'vue'

export default {
    components: { NavBar, EditNew },
    props: ['id'],
    setup(props) {
        const novinka = ref([])

        onMounted(() => {
            const proved = async () => {
                const newszav = await queryForIds(['newsHolstejn'], props.id)
                novinka.value = newszav[0].data
            }

            proved()
        })

        return {novinka}

    }
}
</script>

<style>

</style>
<template>
    <div v-if="!loading">
        <q-table
            :rows="rows"
            :columns="columns"
            row-key="name"
            :rows-per-page= "[11]"
            :rows-per-page-options="[11]"

            :rows-per-page-label="'Řádků na stránku'"
        >
        </q-table>
    </div>
    <div v-else>
        <div class="q-pa-md">
            <q-linear-progress query />
            <q-linear-progress query color="primary" class="q-mt-sm" />
            <q-linear-progress query color="secondary" class="q-mt-sm" />
            <q-linear-progress query color="accent" class="q-mt-sm" />
            <q-linear-progress query color="primary" class="q-mt-sm" />
            <q-linear-progress query color="secondary" class="q-mt-sm" />
            <q-linear-progress query color="accent" class="q-mt-sm" />
        </div>
    </div>


</template>

<script>

import getCount from '@/composables/getCount'

import { ref, onMounted } from 'vue'


export default {
    props: [],
    setup() {

        const columns = [
            {
                label: 'podle kategorií',
                name: 'name',
                required: true,
                align: 'left',
                field: row => row.name,
                format: val => `${val}`,
            },
            { name: 'kategorie', align: 'center', label: 'kategorie', field: 'kategorie'},
            { name: 'celkem', align: 'center', label: 'celkem', field: 'celkem'},
            { name: 'placeno', label: 'z toho placeno', field: 'placeno'},
        ]


        const trasy = ['long', 'mid', 'vycházka(short)']


        const fetchzavod = async (trasa, kategorie) => {
            try {
                const { count } = await getCount(
                    ['zavodniciDtHolstejn', 'DT2024', 'public'],
                    ['trasa', '==', trasa],
                    ['kategorie', '==', kategorie]
                )
                return count
            } catch (error) {
                console.error('Error:', error)
                return (-150)
            }
        }


        const fetchplatby = async(trasa, kategorie) => {
            try {
                const { count } = await getCount(
                    ['zavodniciDtHolstejn', 'DT2024', 'public'],
                    ['trasa', '==', trasa],
                    ['kategorie', '==', kategorie],
                    ['zaplaceno', '==', true]
                )
                return count
            } catch (error) {
                console.error('Error:', error)
                return (-150)
            }
        }

        const loading = ref(false)
        const rows = ref([])
        const getRows = async () => {
            loading.value = true
            try {
                const newRows = []
                for (const trasa of trasy) {

                    const kategorieTrasy = getKategorieForTrasa(trasa)

                    for (const kategorie of kategorieTrasy) {
                        const count = await fetchzavod(trasa, kategorie)
                        const platby = await fetchplatby(trasa, kategorie)
                        newRows.push({
                            name: trasa,
                            kategorie: kategorie,
                            celkem: count, 
                            placeno: platby
                        })
                    }
                    rows.value = newRows
                }
            } catch (error) {
                console.error('Chyba při načítání dat:', error)
            } finally {
                loading.value = false
            }
        }


        const getKategorieForTrasa = (trasa) => {
            if (trasa === 'long') {
                return ['DTM 1: muži do 40-ti let včetně', 
                    'DTW 1: ženy do 35-ti let včetně', 
                    'DTM 2: muži nad 40 let', 
                    'DTW 2: ženy nad 35 let',
                    'PLYŠÁK: všichni závodníci bez psa'
                ]
            }
            if (trasa === 'mid') {
                return ['DMM 1: muži do 40-ti let včetně', 
                    'DMW 1: ženy do 35-ti let včetně', 
                    'DMM 2: muži nad 40 let', 
                    'DMW 2: ženy nad 35 let',
                    'PLYŠÁK: všichni závodníci bez psa'
               ]
            }
            else {
                return ['vycházka(short)']
            }
        }

        onMounted(() => {
            getRows()
        })

        return {
        columns,
        rows,
        loading 
        }

    }


}
</script>

<style>
</style>
<template>
    <div>
        <q-table
            :rows="rows"
            :columns="columns"
            row-key="name"
        >
        </q-table>
    </div>

</template>

<script>

import getCount from '@/composables/getCount'

import { ref, onMounted } from 'vue'


export default {
    props: [],
    setup() {

        const columns = [
            {
                label: 'Statistiky přihlášených a placených podle tras',
                name: 'name',
                required: true,
                align: 'left',
                field: row => row.name,
                format: val => `${val}`,
            },
            { name: 'celkem', align: 'center', label: 'celkem', field: 'celkem'},
            { name: 'placeno', label: 'z toho placeno', field: 'placeno'},
        ]


        const trasy = ['long', 'mid', 'vycházka(short)']

        const fetchzavod = async (trasa) => {
            try {
                const { count } = await getCount(
                ['zavodniciDtHolstejn', 'DT2024', 'public'],
                ['trasa', '==', trasa]
                )
                return count
            } catch (error) {
                console.error('Error:', error)
                return (-150)
            }
        }


        const fetchplatby = async(trasa) => {
            try {
                const { count } = await getCount(
                ['zavodniciDtHolstejn', 'DT2024', 'public'],
                ['trasa', '==', trasa],
                ['zaplaceno', '==', true]
                )
                return count
            } catch (error) {
                console.error('Error:', error)
                return (-150)
            }
        }

        const rows = ref([]);
        const getRows = async () => {
            const newRows = []
            for (const trasa of trasy) {
                const count = await fetchzavod(trasa)
                const platby = await fetchplatby(trasa)
                newRows.push({
                    name: trasa,
                    celkem: count, 
                    placeno: platby
                })
            }
            rows.value = newRows
        }

        onMounted(() => {
            getRows()
        })

        return {
        columns,
        rows
        }

    }


}
</script>

<style>
</style>